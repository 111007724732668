define("ember-body-class/instance-initializers/body-class", ["exports", "@ember/object/evented", "@ember/object", "@ember/routing/route", "@ember/application", "ember-body-class/util/bodyClass", "ember-get-config"], function (_exports, _evented, _object, _route, _application, _bodyClass, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* eslint-disable ember/no-get, ember/no-observers, prettier/prettier */

  function initialize() {
    // Default to true when not set
    let _includeRouteName = true;
    if (_emberGetConfig.default['ember-body-class'] && _emberGetConfig.default['ember-body-class'].includeRouteName === false) {
      _includeRouteName = false;
    }
    _route.default.reopen({
      classNames: [],
      bodyClasses: null,
      init() {
        this._super(...arguments);
        (0, _object.set)(this, 'bodyClasses', []);
      },
      _getRouteDepthClasses() {
        let routeParts = this.get('routeName').split('.');
        let routeDepthClasses = routeParts.slice(0);
        let currentSelector = [];
        routeParts.forEach(part => {
          currentSelector.push(part);
          routeDepthClasses.push(currentSelector.join(`-`));
        });
        return routeDepthClasses;
      },
      addClasses: (0, _evented.on)('activate', function () {
        this._setClassNamesOnBodyElement();
      }),
      _setClassNamesOnBodyElement() {
        const {
          body
        } = (0, _application.getOwner)(this).lookup('service:-document');
        ['bodyClasses', 'classNames'].forEach(classes => {
          this.get(classes).forEach(function (klass) {
            (0, _bodyClass.addClass)(body, klass);
          });
        });
        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(depthClass => {
            (0, _bodyClass.addClass)(body, depthClass);
          });
        }
      },
      updateClasses: (0, _object.observer)('bodyClasses.[]', 'classNames.[]', function () {
        const {
          body
        } = (0, _application.getOwner)(this).lookup('service:-document');
        ['bodyClasses', 'classNames'].forEach(classes => {
          this.get(classes).forEach(function (klass) {
            (0, _bodyClass.removeClass)(body, klass);
          });
        });
        this._setClassNamesOnBodyElement();
      }),
      removeClasses: (0, _evented.on)('deactivate', function () {
        // for some reason we're getting deactivate called too early and it's
        // removing the classes in fastboot only.
        if (typeof FastBoot !== 'undefined') {
          return;
        }
        const {
          body
        } = (0, _application.getOwner)(this).lookup('service:-document');
        ['bodyClasses', 'classNames'].forEach(classes => {
          this.get(classes).forEach(function (klass) {
            (0, _bodyClass.removeClass)(body, klass);
          });
        });
        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(depthClass => {
            (0, _bodyClass.removeClass)(body, depthClass);
          });
        }
      })
    });
  }
  var _default = _exports.default = {
    name: 'body-class',
    initialize: initialize
  };
});