define("@ukgepic/hrsd-ember-ignite/helpers/filter-loader-data", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FilterLoaderDataHelper extends _helper.default {
    compute(_ref) {
      let [filter, data, error, isEmpty, isRunning] = _ref;
      if (!filter || isRunning || error) {
        return {
          data,
          error,
          isEmpty,
          isRunning
        };
      }
      let filtered = data.filter(filter);
      return {
        data: filtered,
        error,
        isRunning,
        isEmpty: !isRunning && filtered.length === 0
      };
    }
  }
  _exports.default = FilterLoaderDataHelper;
});