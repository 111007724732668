define("@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr
    id="{{@tableId}}-row-{{@index}}"
    ...attributes
  >
    {{yield @rowDatas @index}}
  </tr>
  */
  {
    "id": "M5j//skq",
    "block": "[[[11,\"tr\"],[16,1,[29,[[30,1],\"-row-\",[30,2]]]],[17,3],[12],[1,\"\\n  \"],[18,5,[[30,4],[30,2]]],[1,\"\\n\"],[13]],[\"@tableId\",\"@index\",\"&attrs\",\"@rowDatas\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});