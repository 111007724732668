define("@ukgepic/hrsd-ember-ignite/components/ignite-select-multiple", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ukg-select
    ...attributes
    multiple
    {{did-insert this.updateSelectValue}}
    {{did-update this.updateSelectValue @value}}
  >
    <div data-test-select-options-wrapper>
      {{yield}}
    </div>
  </ukg-select>
  */
  {
    "id": "tNKL3stW",
    "block": "[[[11,\"ukg-select\"],[17,1],[24,\"multiple\",\"\"],[4,[38,0],[[30,0,[\"updateSelectValue\"]]],null],[4,[38,1],[[30,0,[\"updateSelectValue\"]],[30,2]],null],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-select-options-wrapper\",\"\"],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\",\"&default\"],false,[\"did-insert\",\"did-update\",\"yield\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-select-multiple.hbs",
    "isStrictMode": false
  });
  let IgniteSelectMultipleComponent = _exports.default = (_class = class IgniteSelectMultipleComponent extends _component2.default {
    updateSelectValue(igniteSelect, _ref) {
      let [value] = _ref;
      igniteSelect.update(value ?? this.args.value ?? []);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "updateSelectValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectValue"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IgniteSelectMultipleComponent);
});