define("@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-header/-cell", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @sort}}
    {{! template-lint-disable no-invalid-interactive }}
    <ukg-th
      aria-sort={{this.ariaSort}}
      initialSortDirection={{this.ukgSortDirection}}
      sortable="true"
      ...attributes
      {{on "click" this.sortAction}}
      {{on "ukgDataTableSortChanged" this.onUkgDataTableSortChanged}}
    >
      {{yield}}
    </ukg-th>
    {{! template-lint-enable no-invalid-interactive }}
  {{else}}
    <ukg-th ...attributes>
      {{yield}}
    </ukg-th>
  {{/if}}
  */
  {
    "id": "lj+vX9UH",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"ukg-th\"],[16,\"aria-sort\",[30,0,[\"ariaSort\"]]],[16,\"initialSortDirection\",[30,0,[\"ukgSortDirection\"]]],[24,\"sortable\",\"true\"],[17,2],[4,[38,1],[\"click\",[30,0,[\"sortAction\"]]],null],[4,[38,1],[\"ukgDataTableSortChanged\",[30,0,[\"onUkgDataTableSortChanged\"]]],null],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"ukg-th\"],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@sort\",\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-header/-cell.hbs",
    "isStrictMode": false
  });
  let IgnitePaginatedDataTableHeaderCellComponent = _exports.default = (_class = class IgnitePaginatedDataTableHeaderCellComponent extends _component2.default {
    get isSortedAsc() {
      return this.args.currentSort === `+${this.args.sort}`;
    }
    get isSortedDesc() {
      return this.args.currentSort === `-${this.args.sort}`;
    }
    get isSorted() {
      return this.isSortedAsc || this.isSortedDesc;
    }
    get ariaSort() {
      if (!this.isSorted) {
        return 'none';
      }
      return this.isSortedAsc ? 'ascending' : 'descending';
    }
    get ukgSortDirection() {
      if (!this.isSorted) {
        return 'none';
      }
      return this.isSortedAsc ? 'asc' : 'desc';
    }
    onUkgDataTableSortChanged(event) {
      let element = event.target;
      element.setSortDirection(this.ukgSortDirection);
    }
    sortAction() {
      this.args.onSortChange(`${this.isSortedAsc ? '-' : '+'}${this.args.sort}`);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onUkgDataTableSortChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUkgDataTableSortChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortAction"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IgnitePaginatedDataTableHeaderCellComponent);
});