define("ember-infinity/-private/evented", ["exports", "ember-infinity/-private/notifier"], function (_exports, _notifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEvented = addEvented;
  // in lieue of a decorator, lets just use Mixin/composition pattern
  function addEvented(Base) {
    return class extends Base {
      on(eventName, listener) {
        return notifierForEvent(this, eventName).addListener(listener);
      }
      off(eventName, listener) {
        return notifierForEvent(this, eventName).removeListener(listener);
      }
      trigger(eventName) {
        const notifier = notifierForEvent(this, eventName);
        if (notifier) {
          for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            args[_key - 1] = arguments[_key];
          }
          notifier.trigger.apply(notifier, args);
        }
      }
    };
  }
  function notifierForEvent(object, eventName) {
    if (object._eventedNotifiers === undefined) {
      object._eventedNotifiers = {};
    }
    let notifier = object._eventedNotifiers[eventName];
    if (!notifier) {
      notifier = object._eventedNotifiers[eventName] = new _notifier.default();
    }
    return notifier;
  }
});