define("@ukgepic/hrsd-ember-ignite/components/ignite-modal", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- Main div goal is to ensure ukg-modal is right removal when this component is wrapped into a conditionnal block --}}
  <div>
    <ukg-modal
      ...attributes
      {{auto-present-dismiss}}
      {{on "ukgModalWillDismiss" @willCloseModal}}
    >
      {{yield}}
    </ukg-modal>
  </div>
  */
  {
    "id": "a6cHR6UW",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[11,\"ukg-modal\"],[17,1],[4,[38,0],null,null],[4,[38,1],[\"ukgModalWillDismiss\",[30,2]],null],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@willCloseModal\",\"&default\"],false,[\"auto-present-dismiss\",\"on\",\"yield\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-modal.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});