define("@ukgepic/hrsd-ember-ignite/components/ignite-task-drawer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let
    (hash
      openDrawer=(fn (mut this.drawerIsOpen) true)
      drawerIsOpen=this.drawerIsOpen
    )
    as |api|
  }}
    <ukg-task-drawer-container ...attributes>
      <ukg-task-drawer
        is-open={{this.drawerIsOpen}}
        visual-state="overlay"
        has-reset-button={{this.hasResetButton}}
        has-apply-button={{this.hasApplyButton}}
        {{on "ukgTaskDrawerResetButtonClicked" this.resetAction}}
        {{on "ukgTaskDrawerApplyButtonClicked" this.applyAction}}
        {{on "ukgTaskDrawerWillClose" (fn (mut this.drawerIsOpen) false)}}
      >
        {{yield
          (hash
            drawer=(component "ignite-task-drawer/-drawer-content")
            api=api
          )
        }}
      </ukg-task-drawer>
  
      {{yield
        (hash
          content=(component "ignite-task-drawer/-main-content")
          api=api
        )
      }}
    </ukg-task-drawer-container>
  {{/let}}
  */
  {
    "id": "lGMkORiR",
    "block": "[[[44,[[28,[37,1],null,[[\"openDrawer\",\"drawerIsOpen\"],[[28,[37,2],[[28,[37,3],[[30,0,[\"drawerIsOpen\"]]],null],true],null],[30,0,[\"drawerIsOpen\"]]]]]],[[[1,\"  \"],[11,\"ukg-task-drawer-container\"],[17,2],[12],[1,\"\\n    \"],[11,\"ukg-task-drawer\"],[16,\"is-open\",[30,0,[\"drawerIsOpen\"]]],[24,\"visual-state\",\"overlay\"],[16,\"has-reset-button\",[30,0,[\"hasResetButton\"]]],[16,\"has-apply-button\",[30,0,[\"hasApplyButton\"]]],[4,[38,4],[\"ukgTaskDrawerResetButtonClicked\",[30,0,[\"resetAction\"]]],null],[4,[38,4],[\"ukgTaskDrawerApplyButtonClicked\",[30,0,[\"applyAction\"]]],null],[4,[38,4],[\"ukgTaskDrawerWillClose\",[28,[37,2],[[28,[37,3],[[30,0,[\"drawerIsOpen\"]]],null],false],null]],null],[12],[1,\"\\n      \"],[18,3,[[28,[37,1],null,[[\"drawer\",\"api\"],[[50,\"ignite-task-drawer/-drawer-content\",0,null,null],[30,1]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[18,3,[[28,[37,1],null,[[\"content\",\"api\"],[[50,\"ignite-task-drawer/-main-content\",0,null,null],[30,1]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]],[\"api\",\"&attrs\",\"&default\"],false,[\"let\",\"hash\",\"fn\",\"mut\",\"on\",\"yield\",\"component\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-task-drawer.hbs",
    "isStrictMode": false
  });
  let IgniteTaskDrawerComponent = _exports.default = (_class = class IgniteTaskDrawerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "drawerIsOpen", _descriptor, this);
    }
    get hasResetButton() {
      return this.args.hasResetButton ?? true;
    }
    get hasApplyButton() {
      return this.args.hasApplyButton ?? true;
    }
    applyAction() {
      this.drawerIsOpen = false;
      this.args.onApplyClicked();
    }
    resetAction() {
      this.drawerIsOpen = false;
      this.args.onResetClicked();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "drawerIsOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "applyAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "applyAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetAction"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IgniteTaskDrawerComponent);
});