define("@ember-data/model/model-b638e17c", ["exports", "@ember/debug", "@ember/string", "@ember/object", "@ember/object/compat", "@glimmer/tracking", "ember", "@ember-data/store", "@ember-data/store/-private", "@ember/array", "@ember/array/proxy", "@ember/object/computed", "@ember/object/promise-proxy-mixin", "@ember/object/proxy", "@ember/object/internals", "@ember-data/tracking/-private", "@embroider/macros/es-compat2"], function (_exports, _debug, _string, _object, _compat, _tracking, _ember, _store, _private, _array, _proxy, _computed, _promiseProxyMixin, _proxy2, _internals, _private2, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.a = _exports.R = _exports.P = _exports.M = _exports.L = _exports.E = void 0;
  _exports.c = computedMacroWithOptionalParams;
  _exports.l = lookupLegacySupport;
  _exports.n = normalizeModelName;
  let cached = (0, _esCompat.default)(require("ember-cached-decorator-polyfill")).cached;
  function isElementDescriptor(args) {
    let [maybeTarget, maybeKey, maybeDesc] = args;
    return (
      // Ensure we have the right number of args
      args.length === 3 && (
      // Make sure the target is a class or object (prototype)
      typeof maybeTarget === 'function' || typeof maybeTarget === 'object' && maybeTarget !== null) &&
      // Make sure the key is a string
      typeof maybeKey === 'string' && (
      // Make sure the descriptor is the right shape
      typeof maybeDesc === 'object' && maybeDesc !== null && 'enumerable' in maybeDesc && 'configurable' in maybeDesc ||
      // TS compatibility
      maybeDesc === undefined)
    );
  }
  function computedMacroWithOptionalParams(fn) {
    return function () {
      for (var _len = arguments.length, maybeDesc = new Array(_len), _key = 0; _key < _len; _key++) {
        maybeDesc[_key] = arguments[_key];
      }
      return isElementDescriptor(maybeDesc) ? fn()(...maybeDesc) : fn(...maybeDesc);
    };
  }
  function normalizeModelName(type) {
    {
      const result = (0, _string.dasherize)(type);
      (false && !(result === type) && (0, _debug.deprecate)(`The resource type '${type}' is not normalized. Update your application code to use '${result}' instead of '${type}'.`, result === type, {
        id: 'ember-data:deprecate-non-strict-types',
        until: '6.0',
        for: 'ember-data',
        since: {
          available: '5.3',
          enabled: '5.3'
        }
      }));
      return result;
    }
    return type;
  }
  function _initializerDefineProperty(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object.keys(descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;
    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }
    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);
    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }
    if (desc.initializer === void 0) {
      Object.defineProperty(target, property, desc);
      desc = null;
    }
    return desc;
  }
  var _dec$1, _dec2, _dec3, _dec4, _class$6, _descriptor$5, _descriptor2$2;

  /**
    @module @ember-data/model
  */

  // we force the type here to our own construct because mixin and extend patterns
  // lose generic signatures. We also do this because we need to Omit `clear` from
  // the type of ArrayProxy as we override it's signature.
  const ArrayProxyWithCustomOverrides = _proxy.default;

  /**
    Holds validation errors for a given record, organized by attribute names.
  
    This class is not directly instantiable.
  
    Every `Model` has an `errors` property that is an instance of
    `Errors`. This can be used to display validation error
    messages returned from the server when a `record.save()` rejects.
  
    For Example, if you had a `User` model that looked like this:
  
    ```app/models/user.js
    import Model, { attr } from '@ember-data/model';
  
    export default class UserModel extends Model {
      @attr('string') username;
      @attr('string') email;
    }
    ```
    And you attempted to save a record that did not validate on the backend:
  
    ```javascript
    let user = store.createRecord('user', {
      username: 'tomster',
      email: 'invalidEmail'
    });
    user.save();
    ```
  
    Your backend would be expected to return an error response that described
    the problem, so that error messages can be generated on the app.
  
    API responses will be translated into instances of `Errors` differently,
    depending on the specific combination of adapter and serializer used. You
    may want to check the documentation or the source code of the libraries
    that you are using, to know how they expect errors to be communicated.
  
    Errors can be displayed to the user by accessing their property name
    to get an array of all the error objects for that property. Each
    error object is a JavaScript object with two keys:
  
    - `message` A string containing the error message from the backend
    - `attribute` The name of the property associated with this error message
  
    ```handlebars
    <label>Username: <Input @value={{@model.username}} /> </label>
    {{#each @model.errors.username as |error|}}
      <div class="error">
        {{error.message}}
      </div>
    {{/each}}
  
    <label>Email: <Input @value={{@model.email}} /> </label>
    {{#each @model.errors.email as |error|}}
      <div class="error">
        {{error.message}}
      </div>
    {{/each}}
    ```
  
    You can also access the special `messages` property on the error
    object to get an array of all the error strings.
  
    ```handlebars
    {{#each @model.errors.messages as |message|}}
      <div class="error">
        {{message}}
      </div>
    {{/each}}
    ```
  
    @class Errors
    @public
    @extends Ember.ArrayProxy
   */
  let Errors = _exports.E = (_dec$1 = (0, _object.computed)(), _dec2 = (0, _computed.mapBy)('content', 'message'), _dec3 = (0, _object.computed)(), _dec4 = (0, _computed.not)('length'), (_class$6 = class Errors extends ArrayProxyWithCustomOverrides {
    constructor() {
      super(...arguments);
      /**
        An array containing all of the error messages for this
        record. This is useful for displaying all errors to the user.
         ```handlebars
        {{#each @model.errors.messages as |message|}}
          <div class="error">
            {{message}}
          </div>
        {{/each}}
        ```
         @property messages
        @public
        @type {Array}
      */
      _initializerDefineProperty(this, "messages", _descriptor$5, this);
      /**
        Total number of errors.
         @property length
        @type {Number}
        @public
        @readOnly
      */
      /**
        `true` if we have no errors.
         @property isEmpty
        @type {Boolean}
        @public
        @readOnly
      */
      _initializerDefineProperty(this, "isEmpty", _descriptor2$2, this);
    }
    /**
      @property errorsByAttributeName
      @type {MapWithDefault}
      @private
    */
    get errorsByAttributeName() {
      return new Map();
    }

    /**
      Returns errors for a given attribute
       ```javascript
      let user = store.createRecord('user', {
        username: 'tomster',
        email: 'invalidEmail'
      });
      user.save().catch(function(){
        user.errors.errorsFor('email'); // returns:
        // [{attribute: "email", message: "Doesn't look like a valid email."}]
      });
      ```
       @method errorsFor
      @public
      @param {String} attribute
      @return {Array}
    */
    errorsFor(attribute) {
      let map = this.errorsByAttributeName;
      let errors = map.get(attribute);
      if (errors === undefined) {
        errors = (0, _array.A)();
        map.set(attribute, errors);
      }

      // Errors may be a native array with extensions turned on. Since we access
      // the array via a method, and not a computed or using `Ember.get`, it does
      // not entangle properly with autotracking, so we entangle manually by
      // getting the `[]` property.
      (0, _object.get)(errors, '[]');
      return errors;
    }
    /**
      @property content
      @type {Array}
      @private
    */
    get content() {
      return (0, _array.A)();
    }

    /**
      @method unknownProperty
      @private
    */
    unknownProperty(attribute) {
      let errors = this.errorsFor(attribute);
      if (errors.length === 0) {
        return undefined;
      }
      return errors;
    }
    /**
     Manually adds errors to the record. This will trigger the `becameInvalid` event/ lifecycle method on
      the record and transition the record into an `invalid` state.
      Example
     ```javascript
      let errors = user.errors;
       // add multiple errors
      errors.add('password', [
        'Must be at least 12 characters',
        'Must contain at least one symbol',
        'Cannot contain your name'
      ]);
       errors.errorsFor('password');
      // =>
      // [
      //   { attribute: 'password', message: 'Must be at least 12 characters' },
      //   { attribute: 'password', message: 'Must contain at least one symbol' },
      //   { attribute: 'password', message: 'Cannot contain your name' },
      // ]
       // add a single error
      errors.add('username', 'This field is required');
       errors.errorsFor('username');
      // =>
      // [
      //   { attribute: 'username', message: 'This field is required' },
      // ]
     ```
      @method add
      @public
      @param {string} attribute - the property name of an attribute or relationship
      @param {string[]|string} messages - an error message or array of error messages for the attribute
     */
    add(attribute, messages) {
      const errors = this._findOrCreateMessages(attribute, messages);
      this.addObjects(errors);
      this.errorsFor(attribute).addObjects(errors);
      this.__record.currentState.notify('isValid');
      this.notifyPropertyChange(attribute);
    }

    /**
      @method _findOrCreateMessages
      @private
    */
    _findOrCreateMessages(attribute, messages) {
      let errors = this.errorsFor(attribute);
      let messagesArray = Array.isArray(messages) ? messages : [messages];
      let _messages = new Array(messagesArray.length);
      for (let i = 0; i < messagesArray.length; i++) {
        let message = messagesArray[i];
        let err = errors.findBy('message', message);
        if (err) {
          _messages[i] = err;
        } else {
          _messages[i] = {
            attribute: attribute,
            message
          };
        }
      }
      return _messages;
    }

    /**
     Manually removes all errors for a given member from the record.
       This will transition the record into a `valid` state, and
      triggers the `becameValid` event and lifecycle method.
      Example:
      ```javascript
      let errors = user.errors;
      errors.add('phone', ['error-1', 'error-2']);
       errors.errorsFor('phone');
      // =>
      // [
      //   { attribute: 'phone', message: 'error-1' },
      //   { attribute: 'phone', message: 'error-2' },
      // ]
       errors.remove('phone');
       errors.errorsFor('phone');
      // => undefined
     ```
     @method remove
      @public
     @param {string} member - the property name of an attribute or relationship
     */
    remove(attribute) {
      if (this.isEmpty) {
        return;
      }
      let content = this.rejectBy('attribute', attribute);
      this.content.setObjects(content);

      // Although errorsByAttributeName.delete is technically enough to sync errors state, we also
      // must mutate the array as well for autotracking
      let errors = this.errorsFor(attribute);
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].attribute === attribute) {
          // .replace from Ember.NativeArray is necessary. JS splice will not work.
          errors.replace(i, 1);
        }
      }
      this.errorsByAttributeName.delete(attribute);
      this.__record.currentState.notify('isValid');
      this.notifyPropertyChange(attribute);
      this.notifyPropertyChange('length');
    }

    /**
     Manually clears all errors for the record.
       This will transition the record into a `valid` state, and
       will trigger the `becameValid` event and lifecycle method.
     Example:
      ```javascript
     let errors = user.errors;
     errors.add('username', ['error-a']);
     errors.add('phone', ['error-1', 'error-2']);
      errors.errorsFor('username');
     // =>
     // [
     //   { attribute: 'username', message: 'error-a' },
     // ]
      errors.errorsFor('phone');
     // =>
     // [
     //   { attribute: 'phone', message: 'error-1' },
     //   { attribute: 'phone', message: 'error-2' },
     // ]
      errors.clear();
      errors.errorsFor('username');
     // => undefined
      errors.errorsFor('phone');
     // => undefined
      errors.messages
     // => []
     ```
     @method clear
     @public
     */
    clear() {
      if (this.isEmpty) {
        return;
      }
      let errorsByAttributeName = this.errorsByAttributeName;
      let attributes = [];
      errorsByAttributeName.forEach(function (_, attribute) {
        attributes.push(attribute);
      });
      errorsByAttributeName.clear();
      attributes.forEach(attribute => {
        this.notifyPropertyChange(attribute);
      });
      this.__record.currentState.notify('isValid');
      super.clear();
    }

    /**
      Checks if there are error messages for the given attribute.
       ```app/controllers/user/edit.js
      import Controller from '@ember/controller';
      import { action } from '@ember/object';
       export default class UserEditController extends Controller {
        @action
        save(user) {
          if (user.errors.has('email')) {
            return alert('Please update your email before attempting to save.');
          }
          user.save();
        }
      }
      ```
       @method has
      @public
      @param {String} attribute
      @return {Boolean} true if there some errors on given attribute
    */
    has(attribute) {
      return this.errorsFor(attribute).length > 0;
    }
  }, (_applyDecoratedDescriptor(_class$6.prototype, "errorsByAttributeName", [_dec$1], Object.getOwnPropertyDescriptor(_class$6.prototype, "errorsByAttributeName"), _class$6.prototype), _descriptor$5 = _applyDecoratedDescriptor(_class$6.prototype, "messages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class$6.prototype, "content", [_dec3], Object.getOwnPropertyDescriptor(_class$6.prototype, "content"), _class$6.prototype), _descriptor2$2 = _applyDecoratedDescriptor(_class$6.prototype, "isEmpty", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class$6));

  /**
    @module @ember-data/store
  */
  /**
    A `ManyArray` is a `MutableArray` that represents the contents of a has-many
    relationship.
  
    The `ManyArray` is instantiated lazily the first time the relationship is
    requested.
  
    This class is not intended to be directly instantiated by consuming applications.
  
    ### Inverses
  
    Often, the relationships in Ember Data applications will have
    an inverse. For example, imagine the following models are
    defined:
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('comment') comments;
    }
    ```
  
    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class CommentModel extends Model {
      @belongsTo('post') post;
    }
    ```
  
    If you created a new instance of `Post` and added
    a `Comment` record to its `comments` has-many
    relationship, you would expect the comment's `post`
    property to be set to the post that contained
    the has-many.
  
    We call the record to which a relationship belongs-to the
    relationship's _owner_.
  
    @class ManyArray
    @public
  */
  class RelatedCollection extends _private.RecordArray {
    /**
      The loading state of this array
       @property {Boolean} isLoaded
      @public
      */

    /**
      `true` if the relationship is polymorphic, `false` otherwise.
       @property {Boolean} isPolymorphic
      @private
      */

    /**
      Metadata associated with the request for async hasMany relationships.
       Example
       Given that the server returns the following JSON payload when fetching a
      hasMany relationship:
       ```js
      {
        "comments": [{
          "id": 1,
          "comment": "This is the first comment",
        }, {
      // ...
        }],
         "meta": {
          "page": 1,
          "total": 5
        }
      }
      ```
       You can then access the meta data via the `meta` property:
       ```js
      let comments = await post.comments;
      let meta = comments.meta;
       // meta.page => 1
      // meta.total => 5
      ```
       @property {Object | null} meta
      @public
      */

    /**
       * Retrieve the links for this relationship
       *
       @property {Object | null} links
       @public
       */

    // @ts-expect-error

    constructor(options) {
      super(options);
      this.isLoaded = options.isLoaded || false;
      this.isAsync = options.isAsync || false;
      this.isPolymorphic = options.isPolymorphic || false;
      this.identifier = options.identifier;
      this.key = options.key;
    }
    [_private.MUTATE](prop, args, result) {
      switch (prop) {
        case 'length 0':
          {
            this._manager.mutate({
              op: 'replaceRelatedRecords',
              record: this.identifier,
              field: this.key,
              value: []
            });
            break;
          }
        case 'replace cell':
          {
            const [index, prior, value] = args;
            this._manager.mutate({
              op: 'replaceRelatedRecord',
              record: this.identifier,
              field: this.key,
              value,
              prior,
              index
            });
            break;
          }
        case 'push':
          this._manager.mutate({
            op: 'addToRelatedRecords',
            record: this.identifier,
            field: this.key,
            value: extractIdentifiersFromRecords(args)
          });
          break;
        case 'pop':
          if (result) {
            this._manager.mutate({
              op: 'removeFromRelatedRecords',
              record: this.identifier,
              field: this.key,
              value: (0, _private.recordIdentifierFor)(result)
            });
          }
          break;
        case 'unshift':
          this._manager.mutate({
            op: 'addToRelatedRecords',
            record: this.identifier,
            field: this.key,
            value: extractIdentifiersFromRecords(args),
            index: 0
          });
          break;
        case 'shift':
          if (result) {
            this._manager.mutate({
              op: 'removeFromRelatedRecords',
              record: this.identifier,
              field: this.key,
              value: (0, _private.recordIdentifierFor)(result),
              index: 0
            });
          }
          break;
        case 'sort':
          this._manager.mutate({
            op: 'sortRelatedRecords',
            record: this.identifier,
            field: this.key,
            value: result.map(_private.recordIdentifierFor)
          });
          break;
        case 'splice':
          {
            const [start, removeCount, ...adds] = args;
            // detect a full replace
            if (removeCount > 0 && adds.length === this[_private.SOURCE].length) {
              this._manager.mutate({
                op: 'replaceRelatedRecords',
                record: this.identifier,
                field: this.key,
                value: extractIdentifiersFromRecords(adds)
              });
              return;
            }
            if (removeCount > 0) {
              this._manager.mutate({
                op: 'removeFromRelatedRecords',
                record: this.identifier,
                field: this.key,
                value: result.map(_private.recordIdentifierFor),
                index: start
              });
            }
            if (adds?.length) {
              this._manager.mutate({
                op: 'addToRelatedRecords',
                record: this.identifier,
                field: this.key,
                value: extractIdentifiersFromRecords(adds),
                index: start
              });
            }
            break;
          }
        default:
          (false && !(false) && (0, _debug.assert)(`unable to convert ${prop} into a transaction that updates the cache state for this record array`));
      }
    }
    notify() {
      const tag = this[_private.IDENTIFIER_ARRAY_TAG];
      tag.shouldReset = true;
      // @ts-expect-error
      (0, _private.notifyArray)(this);
    }

    /**
      Reloads all of the records in the manyArray. If the manyArray
      holds a relationship that was originally fetched using a links url
      Ember Data will revisit the original links url to repopulate the
      relationship.
       If the manyArray holds the result of a `store.query()` reload will
      re-run the original query.
       Example
       ```javascript
      let user = store.peekRecord('user', '1')
      await login(user);
       let permissions = await user.permissions;
      await permissions.reload();
      ```
       @method reload
      @public
    */
    reload(options) {
      // TODO this is odd, we don't ask the store for anything else like this?
      return this._manager.reloadHasMany(this.key, options);
    }

    /**
      Saves all of the records in the `ManyArray`.
       Example
       ```javascript
      let inbox = await store.findRecord('inbox', '1');
      let messages = await inbox.messages;
      messages.forEach((message) => {
        message.isRead = true;
      });
      messages.save();
      ```
       @method save
      @public
      @return {PromiseArray} promise
    */

    /**
      Create a child record within the owner
       @method createRecord
      @public
      @param {Object} hash
      @return {Model} record
    */
    createRecord(hash) {
      const {
        store
      } = this;
      (false && !(this.modelName) && (0, _debug.assert)(`Expected modelName to be set`, this.modelName));
      const record = store.createRecord(this.modelName, hash);
      this.push(record);
      return record;
    }
    destroy() {
      super.destroy(false);
    }
  }
  _exports.R = RelatedCollection;
  RelatedCollection.prototype.isAsync = false;
  RelatedCollection.prototype.isPolymorphic = false;
  RelatedCollection.prototype.identifier = null;
  RelatedCollection.prototype.cache = null;
  RelatedCollection.prototype._inverseIsAsync = false;
  RelatedCollection.prototype.key = '';
  RelatedCollection.prototype.DEPRECATED_CLASS_NAME = 'ManyArray';
  function assertRecordPassedToHasMany(record) {
    (false && !(function () {
      try {
        (0, _private.recordIdentifierFor)(record);
        return true;
      } catch {
        return false;
      }
    }()) && (0, _debug.assert)(`All elements of a hasMany relationship must be instances of Model, you passed $${typeof record}`, function () {
      try {
        (0, _private.recordIdentifierFor)(record);
        return true;
      } catch {
        return false;
      }
    }()));
  }
  function extractIdentifiersFromRecords(records) {
    return records.map(extractIdentifierFromRecord$1);
  }
  function extractIdentifierFromRecord$1(recordOrPromiseRecord) {
    assertRecordPassedToHasMany(recordOrPromiseRecord);
    return (0, _private.recordIdentifierFor)(recordOrPromiseRecord);
  }
  const PromiseObject = _proxy2.default.extend(_promiseProxyMixin.default);
  var _dec, _class$5;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const Extended = PromiseObject;

  /**
   @module @ember-data/model
   */

  /**
    A PromiseBelongsTo is a PromiseObject that also proxies certain method calls
    to the underlying belongsTo model.
    Right now we proxy:
      * `reload()`
    @class PromiseBelongsTo
    @extends PromiseObject
    @private
  */
  let PromiseBelongsTo = _exports.P = (_dec = (0, _object.computed)(), (_class$5 = class PromiseBelongsTo extends Extended {
    get id() {
      const {
        key,
        legacySupport
      } = this._belongsToState;
      const ref = legacySupport.referenceFor('belongsTo', key);
      return ref.id();
    }

    // we don't proxy meta because we would need to proxy it to the relationship state container
    //  however, meta on relationships does not trigger change notifications.
    //  if you need relationship meta, you should do `record.belongsTo(relationshipName).meta()`
    get meta() {
      // eslint-disable-next-line no-constant-condition
      {
        (false && !(false) && (0, _debug.assert)('You attempted to access meta on the promise for the async belongsTo relationship ' + `${this._belongsToState.modelName}:${this._belongsToState.key}'.` + '\nUse `record.belongsTo(relationshipName).meta()` instead.', false));
      }
      return;
    }
    async reload(options) {
      (false && !(this.content !== undefined) && (0, _debug.assert)('You are trying to reload an async belongsTo before it has been created', this.content !== undefined));
      let {
        key,
        legacySupport
      } = this._belongsToState;
      await legacySupport.reloadBelongsTo(key, options);
      return this;
    }
  }, (_applyDecoratedDescriptor(_class$5.prototype, "id", [cached], Object.getOwnPropertyDescriptor(_class$5.prototype, "id"), _class$5.prototype), _applyDecoratedDescriptor(_class$5.prototype, "meta", [_dec], Object.getOwnPropertyDescriptor(_class$5.prototype, "meta"), _class$5.prototype)), _class$5));
  var _class$4, _descriptor$4, _descriptor2$1, _descriptor3, _descriptor4, _descriptor5;
  /**
   @module @ember-data/model
   */
  /**
    This class is returned as the result of accessing an async hasMany relationship
    on an instance of a Model extending from `@ember-data/model`.
  
    A PromiseManyArray is an iterable proxy that allows templates to consume related
    ManyArrays and update once their contents are no longer pending.
  
    In your JS code you should resolve the promise first.
  
    ```js
    const comments = await post.comments;
    ```
  
    @class PromiseManyArray
    @public
  */
  let PromiseManyArray = _exports.a = (_class$4 = class PromiseManyArray {
    constructor(promise, content) {
      //---- Methods/Properties on ArrayProxy that we will keep as our API
      _initializerDefineProperty(this, "content", _descriptor$4, this);
      //----  Properties/Methods from the PromiseProxyMixin that we will keep as our API
      /**
       * Whether the loading promise is still pending
       *
       * @property {boolean} isPending
       * @public
       */
      _initializerDefineProperty(this, "isPending", _descriptor2$1, this);
      /**
       * Whether the loading promise rejected
       *
       * @property {boolean} isRejected
       * @public
       */
      _initializerDefineProperty(this, "isRejected", _descriptor3, this);
      /**
       * Whether the loading promise succeeded
       *
       * @property {boolean} isFulfilled
       * @public
       */
      _initializerDefineProperty(this, "isFulfilled", _descriptor4, this);
      /**
       * Whether the loading promise completed (resolved or rejected)
       *
       * @property {boolean} isSettled
       * @public
       */
      _initializerDefineProperty(this, "isSettled", _descriptor5, this);
      this._update(promise, content);
      this.isDestroyed = false;
    }
    /**
     * Retrieve the length of the content
     * @property length
     * @public
     */
    get length() {
      // shouldn't be needed, but ends up being needed
      // for computed chains even in 4.x
      {
        this['[]'];
      }
      return this.content ? this.content.length : 0;
    }

    // ember-source < 3.23 (e.g. 3.20 lts)
    // requires that the tag `'[]'` be notified
    // on the ArrayProxy in order for `{{#each}}`
    // to recompute. We entangle the '[]' tag from
    get '[]'() {
      {
        return this.content?.length && this.content;
      }
    }

    /**
     * Iterate the proxied content. Called by the glimmer iterator in #each
     * We do not guarantee that forEach will always be available. This
     * may eventually be made to use Symbol.Iterator once glimmer supports it.
     *
     * @method forEach
     * @param cb
     * @returns
     * @private
     */
    forEach(cb) {
      if (this.content && this.length) {
        this.content.forEach(cb);
      }
    }

    /**
     * Reload the relationship
     * @method reload
     * @public
     * @param options
     * @returns
     */
    reload(options) {
      (false && !(this.content) && (0, _debug.assert)('You are trying to reload an async manyArray before it has been created', this.content));
      this.content.reload(options);
      return this;
    }
    /**
     * chain this promise
     *
     * @method then
     * @public
     * @param success
     * @param fail
     * @returns Promise
     */
    then(s, f) {
      return this.promise.then(s, f);
    }

    /**
     * catch errors thrown by this promise
     * @method catch
     * @public
     * @param callback
     * @returns Promise
     */
    catch(cb) {
      return this.promise.catch(cb);
    }

    /**
     * run cleanup after this promise completes
     *
     * @method finally
     * @public
     * @param callback
     * @returns Promise
     */
    finally(cb) {
      return this.promise.finally(cb);
    }

    //---- Methods on EmberObject that we should keep

    destroy() {
      this.isDestroyed = true;
      this.content = null;
      this.promise = null;
    }

    //---- Methods/Properties on ManyArray that we own and proxy to

    /**
     * Retrieve the links for this relationship
     * @property links
     * @public
     */
    get links() {
      return this.content ? this.content.links : undefined;
    }

    /**
     * Retrieve the meta for this relationship
     * @property meta
     * @public
     */
    get meta() {
      return this.content ? this.content.meta : undefined;
    }

    //---- Our own stuff

    _update(promise, content) {
      if (content !== undefined) {
        this.content = content;
      }
      this.promise = tapPromise(this, promise);
    }
    static create(_ref) {
      let {
        promise,
        content
      } = _ref;
      return new this(promise, content);
    }
  }, (_descriptor$4 = _applyDecoratedDescriptor(_class$4.prototype, "content", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class$4.prototype, "length", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class$4.prototype, "length"), _class$4.prototype), _applyDecoratedDescriptor(_class$4.prototype, '[]', [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class$4.prototype, '[]'), _class$4.prototype), _descriptor2$1 = _applyDecoratedDescriptor(_class$4.prototype, "isPending", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class$4.prototype, "isRejected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class$4.prototype, "isFulfilled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class$4.prototype, "isSettled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class$4.prototype, "links", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class$4.prototype, "links"), _class$4.prototype), _applyDecoratedDescriptor(_class$4.prototype, "meta", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class$4.prototype, "meta"), _class$4.prototype)), _class$4);
  function tapPromise(proxy, promise) {
    proxy.isPending = true;
    proxy.isSettled = false;
    proxy.isFulfilled = false;
    proxy.isRejected = false;
    return Promise.resolve(promise).then(content => {
      proxy.isPending = false;
      proxy.isFulfilled = true;
      proxy.isSettled = true;
      proxy.content = content;
      return content;
    }, error => {
      proxy.isPending = false;
      proxy.isFulfilled = false;
      proxy.isRejected = true;
      proxy.isSettled = true;
      throw error;
    });
  }

  /*
    Assert that `addedRecord` has a valid type so it can be added to the
    relationship of the `record`.
  
    The assert basically checks if the `addedRecord` can be added to the
    relationship (specified via `relationshipMeta`) of the `record`.
  
    This utility should only be used internally, as both record parameters must
    be stable record identifiers and the `relationshipMeta` needs to be the meta
    information about the relationship, retrieved via
    `record.relationshipFor(key)`.
  */
  let assertPolymorphicType;
  var _class$3, _descriptor$3;

  /**
    @module @ember-data/model
  */

  function isResourceIdentiferWithRelatedLinks$1(value) {
    return Boolean(value && value.links && value.links.related);
  }

  /**
   A `BelongsToReference` is a low-level API that allows users and
   addon authors to perform meta-operations on a belongs-to
   relationship.
  
   @class BelongsToReference
   @public
   */
  let BelongsToReference = (_class$3 = class BelongsToReference {
    constructor(store, graph, parentIdentifier, belongsToRelationship, key) {
      // unsubscribe tokens given to us by the notification manager
      _initializerDefineProperty(this, "_ref", _descriptor$3, this);
      this.graph = graph;
      this.key = key;
      this.belongsToRelationship = belongsToRelationship;
      this.type = belongsToRelationship.definition.type;
      this.store = store;
      this.___identifier = parentIdentifier;
      this.___relatedToken = null;
      this.___token = store.notifications.subscribe(parentIdentifier, (_, bucket, notifiedKey) => {
        if (bucket === 'relationships' && notifiedKey === key) {
          this._ref++;
        }
      });

      // TODO inverse
    }

    destroy() {
      // TODO @feature we need the notification manager often enough
      // we should potentially just expose it fully public
      this.store.notifications.unsubscribe(this.___token);
      this.___token = null;
      if (this.___relatedToken) {
        this.store.notifications.unsubscribe(this.___relatedToken);
        this.___relatedToken = null;
      }
    }

    /**
     * The identifier of the record that this reference refers to.
     * `null` if no related record is known.
     *
     * @property {StableRecordIdentifier | null} identifier
     * @public
     */
    get identifier() {
      if (this.___relatedToken) {
        this.store.notifications.unsubscribe(this.___relatedToken);
        this.___relatedToken = null;
      }
      let resource = this._resource();
      if (resource && resource.data) {
        const identifier = this.store.identifierCache.getOrCreateRecordIdentifier(resource.data);
        this.___relatedToken = this.store.notifications.subscribe(identifier, (_, bucket, notifiedKey) => {
          if (bucket === 'identity' || bucket === 'attributes' && notifiedKey === 'id') {
            this._ref++;
          }
        });
        return identifier;
      }
      return null;
    }

    /**
     The `id` of the record that this reference refers to. Together, the
     `type()` and `id()` methods form a composite key for the identity
     map. This can be used to access the id of an async relationship
     without triggering a fetch that would normally happen if you
     attempted to use `record.relationship.id`.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
      @belongsTo('user', { async: true, inverse: null }) user;
     }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      // get the identifier of the reference
     if (userRef.remoteType() === "id") {
        let id = userRef.id();
      }
     ```
      @method id
     @public
     @return {String} The id of the record in this belongsTo relationship.
     */
    id() {
      return this.identifier?.id || null;
    }

    /**
     The link Ember Data will use to fetch or reload this belongs-to
     relationship. By default it uses only the "related" resource linkage.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
     export default Model.extend({
        user: belongsTo('user', { async: true, inverse: null })
      });
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              links: {
                related: '/articles/1/author'
              }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      // get the identifier of the reference
     if (userRef.remoteType() === "link") {
        let link = userRef.link();
      }
     ```
      @method link
     @public
     @return {String} The link Ember Data will use to fetch or reload this belongs-to relationship.
     */
    link() {
      let resource = this._resource();
      if (isResourceIdentiferWithRelatedLinks$1(resource)) {
        if (resource.links) {
          let related = resource.links.related;
          return !related || typeof related === 'string' ? related : related.href;
        }
      }
      return null;
    }

    /**
     * any links that have been received for this relationship
     *
     * @method links
     * @public
     * @returns
     */
    links() {
      let resource = this._resource();
      return resource && resource.links ? resource.links : null;
    }

    /**
     The meta data for the belongs-to relationship.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
     export default Model.extend({
        user: belongsTo('user', { async: true, inverse: null })
      });
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              links: {
                related: {
                  href: '/articles/1/author'
                },
              },
              meta: {
                lastUpdated: 1458014400000
              }
            }
          }
        }
      });
      let userRef = blog.belongsTo('user');
      userRef.meta() // { lastUpdated: 1458014400000 }
     ```
      @method meta
      @public
     @return {Object} The meta information for the belongs-to relationship.
     */
    meta() {
      let meta = null;
      let resource = this._resource();
      if (resource && resource.meta && typeof resource.meta === 'object') {
        meta = resource.meta;
      }
      return meta;
    }
    _resource() {
      this._ref; // subscribe
      const cache = this.store.cache;
      return cache.getRelationship(this.___identifier, this.key);
    }

    /**
     This returns a string that represents how the reference will be
     looked up when it is loaded. If the relationship has a link it will
     use the "link" otherwise it defaults to "id".
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      // get the identifier of the reference
     if (commentsRef.remoteType() === "ids") {
       let ids = commentsRef.ids();
     } else if (commentsRef.remoteType() === "link") {
       let link = commentsRef.link();
     }
     ```
      @method remoteType
     @public
     @return {String} The name of the remote type. This should either be `link` or `id`
     */
    remoteType() {
      let value = this._resource();
      if (isResourceIdentiferWithRelatedLinks$1(value)) {
        return 'link';
      }
      return 'id';
    }

    /**
     `push` can be used to update the data in the relationship and Ember
     Data will treat the new data as the canonical value of this
     relationship on the backend.
      Example
      ```app/models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
        @belongsTo('user', { async: true, inverse: null }) user;
      }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      // provide data for reference
     userRef.push({
        data: {
          type: 'user',
          id: 1,
          attributes: {
            username: "@user"
          }
        }
      }).then(function(user) {
        userRef.value() === user;
      });
     ```
      @method push
      @public
     @param {Object} object a JSONAPI document object describing the new value of this relationship.
     @return {Promise<record>} A promise that resolves with the new value in this belongs-to relationship.
     */
    push(data) {
      let jsonApiDoc = data;
      let record = this.store.push(jsonApiDoc);
      const {
        identifier
      } = this.belongsToRelationship;
      this.store._join(() => {
        this.graph.push({
          op: 'replaceRelatedRecord',
          record: identifier,
          field: this.key,
          value: (0, _private.recordIdentifierFor)(record)
        });
      });
      return Promise.resolve(record);
    }

    /**
     `value()` synchronously returns the current value of the belongs-to
     relationship. Unlike `record.relationshipName`, calling
     `value()` on a reference does not trigger a fetch if the async
     relationship is not yet loaded. If the relationship is not loaded
     it will always return `null`.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
       @belongsTo('user', { async: true, inverse: null }) user;
     }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      userRef.value(); // null
      // provide data for reference
     userRef.push({
        data: {
          type: 'user',
          id: 1,
          attributes: {
            username: "@user"
          }
        }
      }).then(function(user) {
        userRef.value(); // user
      });
     ```
      @method value
      @public
     @return {Model} the record in this relationship
     */
    value() {
      let resource = this._resource();
      return resource && resource.data ? this.store.peekRecord(resource.data) : null;
    }

    /**
     Loads a record in a belongs-to relationship if it is not already
     loaded. If the relationship is already loaded this method does not
     trigger a new load.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
       @belongsTo('user', { async: true, inverse: null }) user;
     }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      userRef.value(); // null
      userRef.load().then(function(user) {
        userRef.value() === user
      });
     ```
      You may also pass in an options object whose properties will be
     fed forward. This enables you to pass `adapterOptions` into the
     request given to the adapter via the reference.
      Example
      ```javascript
     userRef.load({ adapterOptions: { isPrivate: true } }).then(function(user) {
       userRef.value() === user;
     });
     ```
     ```app/adapters/user.js
     import Adapter from '@ember-data/adapter';
      export default class UserAdapter extends Adapter {
       findRecord(store, type, id, snapshot) {
         // In the adapter you will have access to adapterOptions.
         let adapterOptions = snapshot.adapterOptions;
       }
     });
     ```
      @method load
      @public
     @param {Object} options the options to pass in.
     @return {Promise} a promise that resolves with the record in this belongs-to relationship.
     */
    load(options) {
      const support = LEGACY_SUPPORT.get(this.___identifier);
      const fetchSyncRel = !this.belongsToRelationship.definition.isAsync && !areAllInverseRecordsLoaded(this.store, this._resource());
      return fetchSyncRel ? support.reloadBelongsTo(this.key, options).then(() => this.value()) : support.getBelongsTo(this.key, options);
    }

    /**
     Triggers a reload of the value in this relationship. If the
     remoteType is `"link"` Ember Data will use the relationship link to
     reload the relationship. Otherwise it will reload the record by its
     id.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class BlogModel extends Model {
       @belongsTo('user', { async: true, inverse: null }) user;
     }
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      userRef.reload().then(function(user) {
        userRef.value() === user
      });
     ```
      You may also pass in an options object whose properties will be
     fed forward. This enables you to pass `adapterOptions` into the
     request given to the adapter via the reference. A full example
     can be found in the `load` method.
      Example
      ```javascript
     userRef.reload({ adapterOptions: { isPrivate: true } })
     ```
      @method reload
      @public
     @param {Object} options the options to pass in.
     @return {Promise} a promise that resolves with the record in this belongs-to relationship after the reload has completed.
     */
    reload(options) {
      const support = LEGACY_SUPPORT.get(this.___identifier);
      return support.reloadBelongsTo(this.key, options).then(() => this.value());
    }
  }, (_descriptor$3 = _applyDecoratedDescriptor(_class$3.prototype, "_ref", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class$3.prototype, "identifier", [cached, _compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class$3.prototype, "identifier"), _class$3.prototype)), _class$3);
  var _class$2, _descriptor$2;

  /**
    @module @ember-data/model
  */

  function isResourceIdentiferWithRelatedLinks(value) {
    return Boolean(value && value.links && value.links.related);
  }
  /**
   A `HasManyReference` is a low-level API that allows users and addon
   authors to perform meta-operations on a has-many relationship.
  
   @class HasManyReference
   @public
   @extends Reference
   */
  let HasManyReference = (_class$2 = class HasManyReference {
    constructor(store, graph, parentIdentifier, hasManyRelationship, key) {
      // unsubscribe tokens given to us by the notification manager
      this.___token = void 0;
      this.___identifier = void 0;
      this.___relatedTokenMap = void 0;
      _initializerDefineProperty(this, "_ref", _descriptor$2, this);
      this.graph = graph;
      this.key = key;
      this.hasManyRelationship = hasManyRelationship;
      this.type = hasManyRelationship.definition.type;
      this.store = store;
      this.___identifier = parentIdentifier;
      this.___token = store.notifications.subscribe(parentIdentifier, (_, bucket, notifiedKey) => {
        if (bucket === 'relationships' && notifiedKey === key) {
          this._ref++;
        }
      });
      this.___relatedTokenMap = new Map();
      // TODO inverse
    }

    destroy() {
      this.store.notifications.unsubscribe(this.___token);
      this.___relatedTokenMap.forEach(token => {
        this.store.notifications.unsubscribe(token);
      });
      this.___relatedTokenMap.clear();
    }

    /**
     * An array of identifiers for the records that this reference refers to.
     *
     * @property {StableRecordIdentifier[]} identifiers
     * @public
     */
    get identifiers() {
      this._ref; // consume the tracked prop

      let resource = this._resource();
      let map = this.___relatedTokenMap;
      this.___relatedTokenMap = new Map();
      if (resource && resource.data) {
        return resource.data.map(resourceIdentifier => {
          const identifier = this.store.identifierCache.getOrCreateRecordIdentifier(resourceIdentifier);
          let token = map.get(identifier);
          if (token) {
            map.delete(identifier);
          } else {
            token = this.store.notifications.subscribe(identifier, (_, bucket, notifiedKey) => {
              if (bucket === 'identity' || bucket === 'attributes' && notifiedKey === 'id') {
                this._ref++;
              }
            });
          }
          this.___relatedTokenMap.set(identifier, token);
          return identifier;
        });
      }
      map.forEach(token => {
        this.store.notifications.unsubscribe(token);
      });
      map.clear();
      return [];
    }
    _resource() {
      const cache = this.store.cache;
      return cache.getRelationship(this.___identifier, this.key);
    }

    /**
     This returns a string that represents how the reference will be
     looked up when it is loaded. If the relationship has a link it will
     use the "link" otherwise it defaults to "id".
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      // get the identifier of the reference
     if (commentsRef.remoteType() === "ids") {
       let ids = commentsRef.ids();
     } else if (commentsRef.remoteType() === "link") {
       let link = commentsRef.link();
     }
     ```
      @method remoteType
     @public
     @return {String} The name of the remote type. This should either be `link` or `ids`
     */
    remoteType() {
      let value = this._resource();
      if (value && value.links && value.links.related) {
        return 'link';
      }
      return 'ids';
    }

    /**
     `ids()` returns an array of the record IDs in this relationship.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      commentsRef.ids(); // ['1']
     ```
      @method ids
      @public
     @return {Array} The ids in this has-many relationship
     */
    ids() {
      return this.identifiers.map(identifier => identifier.id);
    }

    /**
     The link Ember Data will use to fetch or reload this belongs-to
     relationship. By default it uses only the "related" resource linkage.
      Example
      ```javascript
     // models/blog.js
     import Model, { belongsTo } from '@ember-data/model';
     export default Model.extend({
        user: belongsTo('user', { async: true, inverse: null })
      });
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              links: {
                related: '/articles/1/author'
              }
            }
          }
        }
      });
     let userRef = blog.belongsTo('user');
      // get the identifier of the reference
     if (userRef.remoteType() === "link") {
        let link = userRef.link();
      }
     ```
      @method link
     @public
     @return {String} The link Ember Data will use to fetch or reload this belongs-to relationship.
     */
    link() {
      let resource = this._resource();
      if (isResourceIdentiferWithRelatedLinks(resource)) {
        if (resource.links) {
          let related = resource.links.related;
          return !related || typeof related === 'string' ? related : related.href;
        }
      }
      return null;
    }

    /**
     * any links that have been received for this relationship
     *
     * @method links
     * @public
     * @returns
     */
    links() {
      let resource = this._resource();
      return resource && resource.links ? resource.links : null;
    }

    /**
     The meta data for the has-many relationship.
      Example
      ```javascript
     // models/blog.js
     import Model, { hasMany } from '@ember-data/model';
     export default Model.extend({
        users: hasMany('user', { async: true, inverse: null })
      });
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            users: {
              links: {
                related: {
                  href: '/articles/1/authors'
                },
              },
              meta: {
                lastUpdated: 1458014400000
              }
            }
          }
        }
      });
      let usersRef = blog.hasMany('user');
      usersRef.meta() // { lastUpdated: 1458014400000 }
     ```
     @method meta
    @public
    @return {Object} The meta information for the belongs-to relationship.
    */
    meta() {
      let meta = null;
      let resource = this._resource();
      if (resource && resource.meta && typeof resource.meta === 'object') {
        meta = resource.meta;
      }
      return meta;
    }

    /**
     `push` can be used to update the data in the relationship and Ember
     Data will treat the new data as the canonical value of this
     relationship on the backend.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      ```
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      commentsRef.ids(); // ['1']
      commentsRef.push([
     [{ type: 'comment', id: 2 }],
     [{ type: 'comment', id: 3 }],
     ])
      commentsRef.ids(); // ['2', '3']
     ```
      @method push
      @public
     @param {Array|Promise} objectOrPromise a promise that resolves to a JSONAPI document object describing the new value of this relationship.
     @return {ManyArray}
     */
    async push(objectOrPromise) {
      let payload = objectOrPromise;
      let array;
      if (!Array.isArray(payload) && typeof payload === 'object' && Array.isArray(payload.data)) {
        array = payload.data;
      } else {
        array = payload;
      }
      const {
        store
      } = this;
      let identifiers = array.map(obj => {
        let record;
        if ('data' in obj) {
          // TODO deprecate pushing non-valid JSON:API here
          record = store.push(obj);
        } else {
          record = store.push({
            data: obj
          });
        }
        return (0, _store.recordIdentifierFor)(record);
      });
      const {
        identifier
      } = this.hasManyRelationship;
      store._join(() => {
        this.graph.push({
          op: 'replaceRelatedRecords',
          record: identifier,
          field: this.key,
          value: identifiers
        });
      });
      return this.load();
    }
    _isLoaded() {
      let hasRelationshipDataProperty = this.hasManyRelationship.state.hasReceivedData;
      if (!hasRelationshipDataProperty) {
        return false;
      }
      const relationship = this.graph.getData(this.hasManyRelationship.identifier, this.key);
      return relationship.data?.every(identifier => {
        return this.store._instanceCache.recordIsLoaded(identifier, true) === true;
      });
    }

    /**
     `value()` synchronously returns the current value of the has-many
     relationship. Unlike `record.relationshipName`, calling
     `value()` on a reference does not trigger a fetch if the async
     relationship is not yet loaded. If the relationship is not loaded
     it will always return `null`.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      post.comments.then(function(comments) {
       commentsRef.value() === comments
     })
     ```
      @method value
      @public
     @return {ManyArray}
     */
    value() {
      const support = LEGACY_SUPPORT.get(this.___identifier);
      const loaded = this._isLoaded();
      if (!loaded) {
        // subscribe to changes
        // for when we are not loaded yet
        this._ref;
        return null;
      }
      return support.getManyArray(this.key);
    }

    /**
     Loads the relationship if it is not already loaded.  If the
     relationship is already loaded this method does not trigger a new
     load. This causes a request to the specified
     relationship link or reloads all items currently in the relationship.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      commentsRef.load().then(function(comments) {
       //...
     });
     ```
      You may also pass in an options object whose properties will be
     fed forward. This enables you to pass `adapterOptions` into the
     request given to the adapter via the reference.
      Example
      ```javascript
     commentsRef.load({ adapterOptions: { isPrivate: true } })
       .then(function(comments) {
         //...
       });
     ```
      ```app/adapters/comment.js
     export default ApplicationAdapter.extend({
       findMany(store, type, id, snapshots) {
         // In the adapter you will have access to adapterOptions.
         let adapterOptions = snapshots[0].adapterOptions;
       }
     });
     ```
      @method load
      @public
     @param {Object} options the options to pass in.
     @return {Promise} a promise that resolves with the ManyArray in
     this has-many relationship.
     */
    async load(options) {
      const support = LEGACY_SUPPORT.get(this.___identifier);
      const fetchSyncRel = !this.hasManyRelationship.definition.isAsync && !areAllInverseRecordsLoaded(this.store, this._resource());
      return fetchSyncRel ? support.reloadHasMany(this.key, options) : support.getHasMany(this.key, options); // this cast is necessary because typescript does not work properly with custom thenables;
    }

    /**
     Reloads this has-many relationship. This causes a request to the specified
     relationship link or reloads all items currently in the relationship.
      Example
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany('comment', { async: true, inverse: null }) comments;
     }
     ```
      ```javascript
     let post = store.push({
       data: {
         type: 'post',
         id: 1,
         relationships: {
           comments: {
             data: [{ type: 'comment', id: 1 }]
           }
         }
       }
     });
      let commentsRef = post.hasMany('comments');
      commentsRef.reload().then(function(comments) {
       //...
     });
     ```
      You may also pass in an options object whose properties will be
     fed forward. This enables you to pass `adapterOptions` into the
     request given to the adapter via the reference. A full example
     can be found in the `load` method.
      Example
      ```javascript
     commentsRef.reload({ adapterOptions: { isPrivate: true } })
     ```
      @method reload
      @public
     @param {Object} options the options to pass in.
     @return {Promise} a promise that resolves with the ManyArray in this has-many relationship.
     */
    reload(options) {
      const support = LEGACY_SUPPORT.get(this.___identifier);
      return support.reloadHasMany(this.key, options);
    }
  }, (_descriptor$2 = _applyDecoratedDescriptor(_class$2.prototype, "_ref", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class$2.prototype, "identifiers", [cached, _compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class$2.prototype, "identifiers"), _class$2.prototype)), _class$2);
  class LegacySupport {
    constructor(record) {
      this.record = record;
      this.store = (0, _private.storeFor)(record);
      this.identifier = (0, _private.recordIdentifierFor)(record);
      this.cache = (0, _private.peekCache)(record);
      {
        const graphFor = (0, _esCompat.default)(require("@ember-data/graph/-private")).graphFor;
        this.graph = graphFor(this.store);
      }
      this._manyArrayCache = Object.create(null);
      this._relationshipPromisesCache = Object.create(null);
      this._relationshipProxyCache = Object.create(null);
      this._pending = Object.create(null);
      this.references = Object.create(null);
    }
    _syncArray(array) {
      // It’s possible the parent side of the relationship may have been destroyed by this point
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      const currentState = array[_private.SOURCE];
      const identifier = this.identifier;
      let [identifiers, jsonApi] = this._getCurrentState(identifier, array.key);
      if (jsonApi.meta) {
        array.meta = jsonApi.meta;
      }
      if (jsonApi.links) {
        array.links = jsonApi.links;
      }
      currentState.length = 0;
      (0, _private.fastPush)(currentState, identifiers);
    }
    mutate(mutation) {
      this.cache.mutate(mutation);
    }
    _findBelongsTo(key, resource, relationship, options) {
      // TODO @runspired follow up if parent isNew then we should not be attempting load here
      // TODO @runspired follow up on whether this should be in the relationship requests cache
      return this._findBelongsToByJsonApiResource(resource, this.identifier, relationship, options).then(identifier => handleCompletedRelationshipRequest(this, key, relationship, identifier), e => handleCompletedRelationshipRequest(this, key, relationship, null, e));
    }
    reloadBelongsTo(key, options) {
      let loadingPromise = this._relationshipPromisesCache[key];
      if (loadingPromise) {
        return loadingPromise;
      }
      const relationship = this.graph.get(this.identifier, key);
      (false && !(isBelongsTo(relationship)) && (0, _debug.assert)(`Expected ${key} to be a belongs-to relationship`, isBelongsTo(relationship)));
      let resource = this.cache.getRelationship(this.identifier, key);
      relationship.state.hasFailedLoadAttempt = false;
      relationship.state.shouldForceReload = true;
      let promise = this._findBelongsTo(key, resource, relationship, options);
      if (this._relationshipProxyCache[key]) {
        // @ts-expect-error
        return this._updatePromiseProxyFor('belongsTo', key, {
          promise
        });
      }
      return promise;
    }
    getBelongsTo(key, options) {
      const {
        identifier,
        cache
      } = this;
      let resource = cache.getRelationship(this.identifier, key);
      let relatedIdentifier = resource && resource.data ? resource.data : null;
      (false && !(!relatedIdentifier || (0, _private.isStableIdentifier)(relatedIdentifier)) && (0, _debug.assert)(`Expected a stable identifier`, !relatedIdentifier || (0, _private.isStableIdentifier)(relatedIdentifier)));
      const store = this.store;
      const relationship = this.graph.get(this.identifier, key);
      (false && !(isBelongsTo(relationship)) && (0, _debug.assert)(`Expected ${key} to be a belongs-to relationship`, isBelongsTo(relationship)));
      let isAsync = relationship.definition.isAsync;
      let _belongsToState = {
        key,
        store,
        legacySupport: this,
        modelName: relationship.definition.type
      };
      if (isAsync) {
        if (relationship.state.hasFailedLoadAttempt) {
          return this._relationshipProxyCache[key];
        }
        let promise = this._findBelongsTo(key, resource, relationship, options);
        const isLoaded = relatedIdentifier && store._instanceCache.recordIsLoaded(relatedIdentifier);
        return this._updatePromiseProxyFor('belongsTo', key, {
          promise,
          content: isLoaded ? store._instanceCache.getRecord(relatedIdentifier) : null,
          _belongsToState
        });
      } else {
        if (relatedIdentifier === null) {
          return null;
        } else {
          let toReturn = store._instanceCache.getRecord(relatedIdentifier);
          (false && !(toReturn === null || store._instanceCache.recordIsLoaded(relatedIdentifier, true)) && (0, _debug.assert)(`You looked up the '${key}' relationship on a '${identifier.type}' with id ${identifier.id || 'null'} but some of the associated records were not loaded. Either make sure they are all loaded together with the parent record, or specify that the relationship is async (\`belongsTo(<type>, { async: true, inverse: <inverse> })\`)`, toReturn === null || store._instanceCache.recordIsLoaded(relatedIdentifier, true)));
          return toReturn;
        }
      }
    }
    setDirtyBelongsTo(key, value) {
      return this.cache.mutate({
        op: 'replaceRelatedRecord',
        record: this.identifier,
        field: key,
        value: extractIdentifierFromRecord(value)
      },
      // @ts-expect-error
      true);
    }
    _getCurrentState(identifier, field) {
      let jsonApi = this.cache.getRelationship(identifier, field);
      const cache = this.store._instanceCache;
      let identifiers = [];
      if (jsonApi.data) {
        for (let i = 0; i < jsonApi.data.length; i++) {
          const relatedIdentifier = jsonApi.data[i];
          (false && !((0, _private.isStableIdentifier)(relatedIdentifier)) && (0, _debug.assert)(`Expected a stable identifier`, (0, _private.isStableIdentifier)(relatedIdentifier)));
          if (cache.recordIsLoaded(relatedIdentifier, true)) {
            identifiers.push(relatedIdentifier);
          }
        }
      }
      return [identifiers, jsonApi];
    }
    getManyArray(key, definition) {
      {
        let manyArray = this._manyArrayCache[key];
        if (!definition) {
          definition = this.graph.get(this.identifier, key).definition;
        }
        if (!manyArray) {
          const [identifiers, doc] = this._getCurrentState(this.identifier, key);
          manyArray = new RelatedCollection({
            store: this.store,
            type: definition.type,
            identifier: this.identifier,
            cache: this.cache,
            identifiers,
            key,
            meta: doc.meta || null,
            links: doc.links || null,
            isPolymorphic: definition.isPolymorphic,
            isAsync: definition.isAsync,
            _inverseIsAsync: definition.inverseIsAsync,
            manager: this,
            isLoaded: !definition.isAsync,
            allowMutation: true
          });
          this._manyArrayCache[key] = manyArray;
        }
        return manyArray;
      }
      (false && !(false) && (0, _debug.assert)('hasMany only works with the @ember-data/json-api package'));
    }
    fetchAsyncHasMany(key, relationship, manyArray, options) {
      {
        let loadingPromise = this._relationshipPromisesCache[key];
        if (loadingPromise) {
          return loadingPromise;
        }
        const jsonApi = this.cache.getRelationship(this.identifier, key);
        const promise = this._findHasManyByJsonApiResource(jsonApi, this.identifier, relationship, options);
        if (!promise) {
          manyArray.isLoaded = true;
          return Promise.resolve(manyArray);
        }
        loadingPromise = promise.then(() => handleCompletedRelationshipRequest(this, key, relationship, manyArray), e => handleCompletedRelationshipRequest(this, key, relationship, manyArray, e));
        this._relationshipPromisesCache[key] = loadingPromise;
        return loadingPromise;
      }
      (false && !(false) && (0, _debug.assert)('hasMany only works with the @ember-data/json-api package'));
    }
    reloadHasMany(key, options) {
      {
        let loadingPromise = this._relationshipPromisesCache[key];
        if (loadingPromise) {
          return loadingPromise;
        }
        const relationship = this.graph.get(this.identifier, key);
        const {
          definition,
          state
        } = relationship;
        state.hasFailedLoadAttempt = false;
        state.shouldForceReload = true;
        let manyArray = this.getManyArray(key, definition);
        let promise = this.fetchAsyncHasMany(key, relationship, manyArray, options);
        if (this._relationshipProxyCache[key]) {
          return this._updatePromiseProxyFor('hasMany', key, {
            promise
          });
        }
        return promise;
      }
      (false && !(false) && (0, _debug.assert)(`hasMany only works with the @ember-data/json-api package`));
    }
    getHasMany(key, options) {
      {
        const relationship = this.graph.get(this.identifier, key);
        const {
          definition,
          state
        } = relationship;
        let manyArray = this.getManyArray(key, definition);
        if (definition.isAsync) {
          if (state.hasFailedLoadAttempt) {
            return this._relationshipProxyCache[key];
          }
          let promise = this.fetchAsyncHasMany(key, relationship, manyArray, options);
          return this._updatePromiseProxyFor('hasMany', key, {
            promise,
            content: manyArray
          });
        } else {
          (false && !(!anyUnloaded(this.store, relationship)) && (0, _debug.assert)(`You looked up the '${key}' relationship on a '${this.identifier.type}' with id ${this.identifier.id || 'null'} but some of the associated records were not loaded. Either make sure they are all loaded together with the parent record, or specify that the relationship is async ('hasMany(<type>, { async: true, inverse: <inverse> })')`, !anyUnloaded(this.store, relationship)));
          return manyArray;
        }
      }
      (false && !(false) && (0, _debug.assert)(`hasMany only works with the @ember-data/json-api package`));
    }
    _updatePromiseProxyFor(kind, key, args) {
      let promiseProxy = this._relationshipProxyCache[key];
      if (kind === 'hasMany') {
        const {
          promise,
          content
        } = args;
        if (promiseProxy) {
          (false && !('_update' in promiseProxy) && (0, _debug.assert)(`Expected a PromiseManyArray`, '_update' in promiseProxy));
          promiseProxy._update(promise, content);
        } else {
          promiseProxy = this._relationshipProxyCache[key] = new PromiseManyArray(promise, content);
        }
        return promiseProxy;
      }
      if (promiseProxy) {
        const {
          promise,
          content
        } = args;
        (false && !('_belongsToState' in promiseProxy) && (0, _debug.assert)(`Expected a PromiseBelongsTo`, '_belongsToState' in promiseProxy));
        if (content !== undefined) {
          promiseProxy.set('content', content);
        }
        void promiseProxy.set('promise', promise);
      } else {
        promiseProxy = PromiseBelongsTo.create(args);
        this._relationshipProxyCache[key] = promiseProxy;
      }
      return promiseProxy;
    }
    referenceFor(kind, name) {
      let reference = this.references[name];
      if (!reference) {
        const {
          graph,
          identifier
        } = this;
        const relationship = graph.get(identifier, name);
        let relationshipKind = relationship.definition.kind;
        if (relationshipKind === 'belongsTo') {
          reference = new BelongsToReference(this.store, graph, identifier, relationship, name);
        } else if (relationshipKind === 'hasMany') {
          reference = new HasManyReference(this.store, graph, identifier, relationship, name);
        }
        this.references[name] = reference;
      }
      return reference;
    }
    _findHasManyByJsonApiResource(resource, parentIdentifier, relationship) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      {
        if (!resource) {
          return;
        }
        const {
          definition,
          state
        } = relationship;
        const adapter = this.store.adapterFor(definition.type);
        const {
          isStale,
          hasDematerializedInverse,
          hasReceivedData,
          isEmpty,
          shouldForceReload
        } = state;
        const allInverseRecordsAreLoaded = areAllInverseRecordsLoaded(this.store, resource);
        const identifiers = resource.data;
        const shouldFindViaLink = resource.links && resource.links.related && (typeof adapter.findHasMany === 'function' || typeof identifiers === 'undefined') && (shouldForceReload || hasDematerializedInverse || isStale || !allInverseRecordsAreLoaded && !isEmpty);
        const relationshipMeta = this.store.getSchemaDefinitionService().relationshipsDefinitionFor({
          type: definition.inverseType
        })[definition.key];
        const request = {
          useLink: shouldFindViaLink,
          field: relationshipMeta,
          links: resource.links,
          meta: resource.meta,
          options,
          record: parentIdentifier
        };

        // fetch via link
        if (shouldFindViaLink) {
          (false && !(!identifiers || Array.isArray(identifiers)) && (0, _debug.assert)(`Expected collection to be an array`, !identifiers || Array.isArray(identifiers)));
          (false && !(!identifiers || identifiers.every(_private.isStableIdentifier)) && (0, _debug.assert)(`Expected stable identifiers`, !identifiers || identifiers.every(_private.isStableIdentifier)));
          return this.store.request({
            op: 'findHasMany',
            records: identifiers || [],
            data: request,
            cacheOptions: {
              [Symbol.for('ember-data:skip-cache')]: true
            }
          });
        }
        const preferLocalCache = hasReceivedData && !isEmpty;
        const hasLocalPartialData = hasDematerializedInverse || isEmpty && Array.isArray(identifiers) && identifiers.length > 0;
        const attemptLocalCache = !shouldForceReload && !isStale && (preferLocalCache || hasLocalPartialData);
        if (attemptLocalCache && allInverseRecordsAreLoaded) {
          return;
        }
        const hasData = hasReceivedData && !isEmpty;
        if (attemptLocalCache || hasData || hasLocalPartialData) {
          (false && !(Array.isArray(identifiers)) && (0, _debug.assert)(`Expected collection to be an array`, Array.isArray(identifiers)));
          (false && !(identifiers.every(_private.isStableIdentifier)) && (0, _debug.assert)(`Expected stable identifiers`, identifiers.every(_private.isStableIdentifier)));
          options.reload = options.reload || !attemptLocalCache || undefined;
          return this.store.request({
            op: 'findHasMany',
            records: identifiers,
            data: request,
            cacheOptions: {
              [Symbol.for('ember-data:skip-cache')]: true
            }
          });
        }

        // we were explicitly told we have no data and no links.
        //   TODO if the relationshipIsStale, should we hit the adapter anyway?
        return;
      }
      (false && !(false) && (0, _debug.assert)(`hasMany only works with the @ember-data/json-api package`));
    }
    _findBelongsToByJsonApiResource(resource, parentIdentifier, relationship) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      if (!resource) {
        return Promise.resolve(null);
      }
      const key = relationship.definition.key;

      // interleaved promises mean that we MUST cache this here
      // in order to prevent infinite re-render if the request
      // fails.
      if (this._pending[key]) {
        return this._pending[key];
      }
      const identifier = resource.data ? resource.data : null;
      (false && !(!identifier || (0, _private.isStableIdentifier)(identifier)) && (0, _debug.assert)(`Expected a stable identifier`, !identifier || (0, _private.isStableIdentifier)(identifier)));
      let {
        isStale,
        hasDematerializedInverse,
        hasReceivedData,
        isEmpty,
        shouldForceReload
      } = relationship.state;
      const allInverseRecordsAreLoaded = areAllInverseRecordsLoaded(this.store, resource);
      const shouldFindViaLink = resource.links?.related && (shouldForceReload || hasDematerializedInverse || isStale || !allInverseRecordsAreLoaded && !isEmpty);
      const relationshipMeta = this.store.getSchemaDefinitionService().relationshipsDefinitionFor(this.identifier)[relationship.definition.key];
      (false && !(relationshipMeta) && (0, _debug.assert)(`Attempted to access a belongsTo relationship but no definition exists for it`, relationshipMeta));
      const request = {
        useLink: shouldFindViaLink,
        field: relationshipMeta,
        links: resource.links,
        meta: resource.meta,
        options,
        record: parentIdentifier
      };

      // fetch via link
      if (shouldFindViaLink) {
        const future = this.store.request({
          op: 'findBelongsTo',
          records: identifier ? [identifier] : [],
          data: request,
          cacheOptions: {
            [Symbol.for('ember-data:skip-cache')]: true
          }
        });
        this._pending[key] = future.then(doc => doc.content).finally(() => {
          this._pending[key] = undefined;
        });
        return this._pending[key];
      }
      const preferLocalCache = hasReceivedData && allInverseRecordsAreLoaded && !isEmpty;
      const hasLocalPartialData = hasDematerializedInverse || isEmpty && resource.data;
      // null is explicit empty, undefined is "we don't know anything"
      const localDataIsEmpty = !identifier;
      const attemptLocalCache = !shouldForceReload && !isStale && (preferLocalCache || hasLocalPartialData);

      // we dont need to fetch and are empty
      if (attemptLocalCache && localDataIsEmpty) {
        return Promise.resolve(null);
      }

      // we dont need to fetch because we are local state
      const resourceIsLocal = identifier?.id === null;
      if (attemptLocalCache && allInverseRecordsAreLoaded || resourceIsLocal) {
        return Promise.resolve(identifier);
      }

      // we may need to fetch
      if (identifier) {
        (false && !(identifier) && (0, _debug.assert)(`Cannot fetch belongs-to relationship with no information`, identifier));
        options.reload = options.reload || !attemptLocalCache || undefined;
        this._pending[key] = this.store.request({
          op: 'findBelongsTo',
          records: [identifier],
          data: request,
          cacheOptions: {
            [Symbol.for('ember-data:skip-cache')]: true
          }
        }).then(doc => doc.content).finally(() => {
          this._pending[key] = undefined;
        });
        return this._pending[key];
      }

      // we were explicitly told we have no data and no links.
      //   TODO if the relationshipIsStale, should we hit the adapter anyway?
      return Promise.resolve(null);
    }
    destroy() {
      this.isDestroying = true;
      let cache = this._manyArrayCache;
      this._manyArrayCache = Object.create(null);
      Object.keys(cache).forEach(key => {
        cache[key].destroy();
      });
      cache = this._relationshipProxyCache;
      this._relationshipProxyCache = Object.create(null);
      Object.keys(cache).forEach(key => {
        const proxy = cache[key];
        if (proxy.destroy) {
          proxy.destroy();
        }
      });
      cache = this.references;
      this.references = Object.create(null);
      Object.keys(cache).forEach(key => {
        cache[key].destroy();
      });
      this.isDestroyed = true;
    }
  }
  function handleCompletedRelationshipRequest(recordExt, key, relationship, value, error) {
    delete recordExt._relationshipPromisesCache[key];
    relationship.state.shouldForceReload = false;
    const isHasMany = relationship.definition.kind === 'hasMany';
    if (isHasMany) {
      // we don't notify the record property here to avoid refetch
      // only the many array
      value.notify();
    }
    if (error) {
      relationship.state.hasFailedLoadAttempt = true;
      let proxy = recordExt._relationshipProxyCache[key];
      // belongsTo relationships are sometimes unloaded
      // when a load fails, in this case we need
      // to make sure that we aren't proxying
      // to destroyed content
      // for the sync belongsTo reload case there will be no proxy
      // for the async reload case there will be no proxy if the ui
      // has never been accessed
      if (proxy && !isHasMany) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (proxy.content && proxy.content.isDestroying) {
          proxy.set('content', null);
        }
        recordExt.store.notifications._flush();
      }
      throw error;
    }
    if (isHasMany) {
      value.isLoaded = true;
    } else {
      recordExt.store.notifications._flush();
    }
    relationship.state.hasFailedLoadAttempt = false;
    // only set to not stale if no error is thrown
    relationship.state.isStale = false;
    return isHasMany || !value ? value : recordExt.store.peekRecord(value);
  }
  function extractIdentifierFromRecord(record) {
    if (!record) {
      return null;
    }
    return (0, _private.recordIdentifierFor)(record);
  }
  function anyUnloaded(store, relationship) {
    const graph = store._graph;
    const relationshipData = graph.getData(relationship.identifier, relationship.definition.key);
    const state = relationshipData.data;
    const cache = store._instanceCache;
    const unloaded = state?.find(s => {
      let isLoaded = cache.recordIsLoaded(s, true);
      return !isLoaded;
    });
    return unloaded || false;
  }
  function areAllInverseRecordsLoaded(store, resource) {
    const instanceCache = store._instanceCache;
    const identifiers = resource.data;
    if (Array.isArray(identifiers)) {
      (false && !(identifiers.every(_private.isStableIdentifier)) && (0, _debug.assert)(`Expected stable identifiers`, identifiers.every(_private.isStableIdentifier))); // treat as collection
      // check for unloaded records
      return identifiers.every(identifier => instanceCache.recordIsLoaded(identifier));
    }

    // treat as single resource
    if (!identifiers) return true;
    (false && !((0, _private.isStableIdentifier)(identifiers)) && (0, _debug.assert)(`Expected stable identifiers`, (0, _private.isStableIdentifier)(identifiers)));
    return instanceCache.recordIsLoaded(identifiers);
  }
  function isBelongsTo(relationship) {
    return relationship.definition.kind === 'belongsTo';
  }
  function notifyChanges(identifier, value, key, record, store) {
    if (value === 'attributes') {
      if (key) {
        notifyAttribute(store, identifier, key, record);
      } else {
        record.eachAttribute(name => {
          notifyAttribute(store, identifier, name, record);
        });
      }
    } else if (value === 'relationships') {
      if (key) {
        let meta = record.constructor.relationshipsByName.get(key);
        notifyRelationship(identifier, key, record, meta);
      } else {
        record.eachRelationship((name, meta) => {
          notifyRelationship(identifier, name, record, meta);
        });
      }
    } else if (value === 'identity') {
      record.notifyPropertyChange('id');
    }
  }
  function notifyRelationship(identifier, key, record, meta) {
    if (meta.kind === 'belongsTo') {
      record.notifyPropertyChange(key);
    } else if (meta.kind === 'hasMany') {
      let support = LEGACY_SUPPORT.get(identifier);
      let manyArray = support && support._manyArrayCache[key];
      let hasPromise = support && support._relationshipPromisesCache[key];
      if (manyArray && hasPromise) {
        // do nothing, we will notify the ManyArray directly
        // once the fetch has completed.
        return;
      }
      if (manyArray) {
        manyArray.notify();

        //We need to notifyPropertyChange in the adding case because we need to make sure
        //we fetch the newly added record in case it is unloaded
        //TODO(Igor): Consider whether we could do this only if the record state is unloaded
        if (!meta.options || meta.options.async || meta.options.async === undefined) {
          record.notifyPropertyChange(key);
        }
      }
    }
  }
  function notifyAttribute(store, identifier, key, record) {
    let currentValue = (0, _internals.cacheFor)(record, key);
    const cache = store.cache;
    if (currentValue !== cache.getAttr(identifier, key)) {
      record.notifyPropertyChange(key);
    }
  }
  var _class$1, _descriptor$1, _class3, _descriptor2;
  const SOURCE_POINTER_REGEXP = /^\/?data\/(attributes|relationships)\/(.*)/;
  const SOURCE_POINTER_PRIMARY_REGEXP = /^\/?data/;
  const PRIMARY_ATTRIBUTE_KEY = 'base';
  function isInvalidError(error) {
    return error && error.isAdapterError === true && error.code === 'InvalidError';
  }

  /**
   * Tag provides a cache for a getter
   * that recomputes only when a specific
   * tracked property that it manages is dirtied.
   *
   * This allows us to bust the cache for a value
   * that otherwise doesn't access anything tracked
   * as well as control the timing of that notification.
   *
   * @internal
   */
  let Tag = (_class$1 = class Tag {
    constructor() {
      _initializerDefineProperty(this, "ref", _descriptor$1, this);
      this.rev = 1;
      this.isDirty = true;
      this.value = undefined;
      /*
       * whether this was part of a transaction when mutated
       */
      this.t = false;
    }
    notify() {
      this.isDirty = true;
      (0, _private2.addToTransaction)(this);
      this.rev++;
    }
    consume(v) {
      this.isDirty = false;
      this.value = v; // set cached value
    }
  }, _descriptor$1 = _applyDecoratedDescriptor(_class$1.prototype, "ref", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _class$1);
  const Tags = new WeakMap();
  function getTag(record, key) {
    let tags = Tags.get(record);
    if (!tags) {
      tags = Object.create(null);
      Tags.set(record, tags);
    }
    // @ts-expect-error
    return tags[key] = tags[key] || new Tag();
  }
  function peekTag(record, key) {
    let tags = Tags.get(record);
    return tags && tags[key];
  }

  /**
   * A decorattor that caches a getter while
   * providing the ability to bust that cache
   * when we so choose in a way that notifies
   * glimmer's tracking system.
   *
   * @internal
   */
  function tagged(_target, key, desc) {
    const getter = desc.get;
    const setter = desc.set;
    desc.get = function () {
      let tag = getTag(this, key);
      (0, _private2.subscribe)(tag);
      if (tag.isDirty) {
        tag.consume(getter.call(this));
      }
      return tag.value;
    };
    desc.set = function (v) {
      getTag(this, key); // ensure tag is setup in case we want to use it.
      // probably notify here but not yet.
      setter.call(this, v);
    };
    (0, _compat.dependentKeyCompat)(desc);
    return desc;
  }

  /**
  Historically EmberData managed a state machine
  for each record, the localState for which
  was reflected onto Model.
  
  This implements the flags and stateName for backwards compat
  with the state tree that used to be possible (listed below).
  
  stateName and dirtyType are candidates for deprecation.
  
  root
    empty
      deleted    // hidden from stateName
      preloaded  // hidden from stateName
  
    loading
       empty     // hidden from stateName
       preloaded // hidden from stateName
  
    loaded
      saved
      updated
        uncommitted
        invalid
        inFlight
      created
        uncommitted
        invalid
        inFlight
  
    deleted
      saved
        new      // hidden from stateName
      uncommitted
      invalid
      inFlight
  
    @internal
  */
  let RecordState = (_class3 = class RecordState {
    constructor(record) {
      _initializerDefineProperty(this, "isSaving", _descriptor2, this);
      const store = (0, _store.storeFor)(record);
      const identity = (0, _private.recordIdentifierFor)(record);
      this.identifier = identity;
      this.record = record;
      this.cache = store.cache;
      this.pendingCount = 0;
      this.fulfilledCount = 0;
      this.rejectedCount = 0;
      this._errorRequests = [];
      this._lastError = null;
      let requests = store.getRequestStateService();
      let notifications = store.notifications;
      const handleRequest = req => {
        if (req.type === 'mutation') {
          switch (req.state) {
            case 'pending':
              this.isSaving = true;
              break;
            case 'rejected':
              this.isSaving = false;
              this._lastError = req;
              if (!(req.response && isInvalidError(req.response.data))) {
                this._errorRequests.push(req);
              }
              notifyErrorsStateChanged(this);
              break;
            case 'fulfilled':
              this._errorRequests = [];
              this._lastError = null;
              this.isSaving = false;
              this.notify('isDirty');
              notifyErrorsStateChanged(this);
              break;
          }
        } else {
          switch (req.state) {
            case 'pending':
              this.pendingCount++;
              this.notify('isLoading');
              break;
            case 'rejected':
              this.pendingCount--;
              this._lastError = req;
              if (!(req.response && isInvalidError(req.response.data))) {
                this._errorRequests.push(req);
              }
              this.notify('isLoading');
              notifyErrorsStateChanged(this);
              break;
            case 'fulfilled':
              this.pendingCount--;
              this.fulfilledCount++;
              this.notify('isLoading');
              this.notify('isDirty');
              notifyErrorsStateChanged(this);
              this._errorRequests = [];
              this._lastError = null;
              break;
          }
        }
      };
      requests.subscribeForRecord(identity, handleRequest);

      // we instantiate lazily
      // so we grab anything we don't have yet
      {
        const lastRequest = requests.getLastRequestForRecord(identity);
        if (lastRequest) {
          handleRequest(lastRequest);
        }
      }
      this.handler = notifications.subscribe(identity, (identifier, type, key) => {
        switch (type) {
          case 'state':
            this.notify('isSaved');
            this.notify('isNew');
            this.notify('isDeleted');
            this.notify('isDirty');
            break;
          case 'attributes':
            this.notify('isEmpty');
            this.notify('isDirty');
            break;
          case 'errors':
            this.updateInvalidErrors(this.record.errors);
            this.notify('isValid');
            break;
        }
      });
    }
    destroy() {
      (0, _store.storeFor)(this.record).notifications.unsubscribe(this.handler);
    }
    notify(key) {
      getTag(this, key).notify();
    }
    updateInvalidErrors(errors) {
      (false && !(typeof this.cache.getErrors === 'function') && (0, _debug.assert)(`Expected the Cache instance for ${this.identifier.lid}  to implement getErrors(identifier)`, typeof this.cache.getErrors === 'function'));
      let jsonApiErrors = this.cache.getErrors(this.identifier);
      errors.clear();
      for (let i = 0; i < jsonApiErrors.length; i++) {
        let error = jsonApiErrors[i];
        if (error.source && error.source.pointer) {
          let keyMatch = error.source.pointer.match(SOURCE_POINTER_REGEXP);
          let key;
          if (keyMatch) {
            key = keyMatch[2];
          } else if (error.source.pointer.search(SOURCE_POINTER_PRIMARY_REGEXP) !== -1) {
            key = PRIMARY_ATTRIBUTE_KEY;
          }
          if (key) {
            let errMsg = error.detail || error.title;
            errors.add(key, errMsg);
          }
        }
      }
    }
    cleanErrorRequests() {
      this.notify('isValid');
      this.notify('isError');
      this.notify('adapterError');
      this._errorRequests = [];
      this._lastError = null;
    }
    get isLoading() {
      return !this.isLoaded && this.pendingCount > 0 && this.fulfilledCount === 0;
    }
    get isLoaded() {
      if (this.isNew) {
        return true;
      }
      return this.fulfilledCount > 0 || !this.isEmpty;
    }
    get isSaved() {
      let rd = this.cache;
      if (this.isDeleted) {
        (false && !(rd.isDeletionCommitted) && (0, _debug.assert)(`Expected Cache to implement isDeletionCommitted()`, rd.isDeletionCommitted));
        return rd.isDeletionCommitted(this.identifier);
      }
      if (this.isNew || this.isEmpty || !this.isValid || this.isDirty || this.isLoading) {
        return false;
      }
      return true;
    }
    get isEmpty() {
      let rd = this.cache;
      // TODO this is not actually an RFC'd concept. Determine the
      // correct heuristic to replace this with.
      (false && !(rd.isEmpty) && (0, _debug.assert)(`Expected Cache to implement isEmpty()`, rd.isEmpty));
      return !this.isNew && rd.isEmpty(this.identifier);
    }
    get isNew() {
      let rd = this.cache;
      (false && !(rd.isNew) && (0, _debug.assert)(`Expected Cache to implement isNew()`, rd.isNew));
      return rd.isNew(this.identifier);
    }
    get isDeleted() {
      let rd = this.cache;
      (false && !(rd.isDeleted) && (0, _debug.assert)(`Expected Cache to implement isDeleted()`, rd.isDeleted));
      return rd.isDeleted(this.identifier);
    }
    get isValid() {
      return this.record.errors.length === 0;
    }
    get isDirty() {
      let rd = this.cache;
      if (this.isEmpty || rd.isDeletionCommitted(this.identifier) || this.isDeleted && this.isNew) {
        return false;
      }
      return this.isDeleted || this.isNew || rd.hasChangedAttrs(this.identifier);
    }
    get isError() {
      let errorReq = this._errorRequests[this._errorRequests.length - 1];
      if (!errorReq) {
        return false;
      } else {
        return true;
      }
    }
    get adapterError() {
      let request = this._lastError;
      if (!request) {
        return null;
      }
      return request.state === 'rejected' && request.response.data;
    }
    get isPreloaded() {
      return !this.isEmpty && this.isLoading;
    }
    get stateName() {
      // we might be empty while loading so check this first
      if (this.isLoading) {
        return 'root.loading';

        // got nothing yet or were unloaded
      } else if (this.isEmpty) {
        return 'root.empty';

        // deleted substates
      } else if (this.isDeleted) {
        if (this.isSaving) {
          return 'root.deleted.inFlight';
        } else if (this.isSaved) {
          // TODO ensure isSaved isn't true from previous requests
          return 'root.deleted.saved';
        } else if (!this.isValid) {
          return 'root.deleted.invalid';
        } else {
          return 'root.deleted.uncommitted';
        }

        // loaded.created substates
      } else if (this.isNew) {
        if (this.isSaving) {
          return 'root.loaded.created.inFlight';
        } else if (!this.isValid) {
          return 'root.loaded.created.invalid';
        }
        return 'root.loaded.created.uncommitted';

        // loaded.updated substates
      } else if (this.isSaving) {
        return 'root.loaded.updated.inFlight';
      } else if (!this.isValid) {
        return 'root.loaded.updated.invalid';
      } else if (this.isDirty) {
        return 'root.loaded.updated.uncommitted';

        // if nothing remains, we are loaded saved!
      } else {
        return 'root.loaded.saved';
      }
    }
    get dirtyType() {
      // we might be empty while loading so check this first
      if (this.isLoading || this.isEmpty) {
        return '';

        // deleted substates
      } else if (this.isDirty && this.isDeleted) {
        return 'deleted';

        // loaded.created substates
      } else if (this.isNew) {
        return 'created';

        // loaded.updated substates
      } else if (this.isSaving || !this.isValid || this.isDirty) {
        return 'updated';

        // if nothing remains, we are loaded saved!
      } else {
        return '';
      }
    }
  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "isSaving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "isLoading", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isLoading"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isLoaded", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isLoaded"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isSaved", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isSaved"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isEmpty", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isEmpty"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isNew", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isNew"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isDeleted", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isDeleted"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isValid", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isValid"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isDirty", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isDirty"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isError", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "isError"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "adapterError", [tagged], Object.getOwnPropertyDescriptor(_class3.prototype, "adapterError"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "isPreloaded", [cached], Object.getOwnPropertyDescriptor(_class3.prototype, "isPreloaded"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "stateName", [cached], Object.getOwnPropertyDescriptor(_class3.prototype, "stateName"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "dirtyType", [cached], Object.getOwnPropertyDescriptor(_class3.prototype, "dirtyType"), _class3.prototype)), _class3);
  function notifyErrorsStateChanged(state) {
    state.notify('isValid');
    state.notify('isError');
    state.notify('adapterError');
  }
  var _class, _descriptor, _class2;
  const {
    changeProperties
  } = _ember.default;
  const LEGACY_SUPPORT = _exports.L = new Map();
  function lookupLegacySupport(record) {
    const identifier = (0, _store.recordIdentifierFor)(record);
    let support = LEGACY_SUPPORT.get(identifier);
    if (!support) {
      (false && !(!record.isDestroyed && !record.isDestroying) && (0, _debug.assert)(`Memory Leak Detected`, !record.isDestroyed && !record.isDestroying));
      support = new LegacySupport(record);
      LEGACY_SUPPORT.set(identifier, support);
      LEGACY_SUPPORT.set(record, support);
    }
    return support;
  }
  function findPossibleInverses(type, inverseType, name, relationshipsSoFar) {
    let possibleRelationships = relationshipsSoFar || [];
    let relationshipMap = inverseType.relationships;
    if (!relationshipMap) {
      return possibleRelationships;
    }
    let relationshipsForType = relationshipMap.get(type.modelName);
    let relationships = Array.isArray(relationshipsForType) ? relationshipsForType.filter(relationship => {
      let optionsForRelationship = relationship.options;
      if (!optionsForRelationship.inverse && optionsForRelationship.inverse !== null) {
        return true;
      }
      return name === optionsForRelationship.inverse;
    }) : null;
    if (relationships) {
      possibleRelationships.push.apply(possibleRelationships, relationships);
    }

    //Recurse to support polymorphism
    if (type.superclass) {
      findPossibleInverses(type.superclass, inverseType, name, possibleRelationships);
    }
    return possibleRelationships;
  }

  /*
   * This decorator allows us to lazily compute
   * an expensive getter on first-access and thereafter
   * never recompute it.
   */
  function computeOnce(target, key, desc) {
    const cache = new WeakMap();
    let getter = desc.get;
    desc.get = function () {
      let meta = cache.get(this);
      if (!meta) {
        meta = {
          hasComputed: false,
          value: undefined
        };
        cache.set(this, meta);
      }
      if (!meta.hasComputed) {
        meta.value = getter.call(this);
        meta.hasComputed = true;
      }
      return meta.value;
    };
    return desc;
  }

  /**
    Base class from which Models can be defined.
  
    ```js
    import Model, { attr } from '@ember-data/model';
  
    export default class User extends Model {
      @attr name;
    }
    ```
  
    @class Model
    @public
    @extends Ember.EmberObject
  */
  let Model = _exports.M = (_class = (_class2 = class Model extends _object.default {
    constructor() {
      super(...arguments);
      this.___private_notifications = void 0;
      /**
        If `true` the store is attempting to reload the record from the adapter.
         Example
         ```javascript
        record.isReloading; // false
        record.reload();
        record.isReloading; // true
        ```
         @property isReloading
        @public
        @type {Boolean}
        @readOnly
      */
      _initializerDefineProperty(this, "isReloading", _descriptor, this);
    }
    init() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const createProps = options._createProps;
      const _secretInit = options._secretInit;
      options._createProps = null;
      options._secretInit = null;
      let store = this.store = _secretInit.store;
      super.init(options);
      let identity = _secretInit.identifier;
      _secretInit.cb(this, _secretInit.cache, identity, _secretInit.store);
      this.___recordState = null;
      this.setProperties(createProps);
      let notifications = store.notifications;
      this.___private_notifications = notifications.subscribe(identity, (identifier, type, key) => {
        notifyChanges(identifier, type, key, this, store);
      });
    }
    destroy() {
      const identifier = (0, _store.recordIdentifierFor)(this);
      this.___recordState?.destroy();
      const store = (0, _store.storeFor)(this);
      store.notifications.unsubscribe(this.___private_notifications);
      // Legacy behavior is to notify the relationships on destroy
      // such that they "clear". It's uncertain this behavior would
      // be good for a new model paradigm, likely cheaper and safer
      // to simply not notify, for this reason the store does not itself
      // notify individual changes once the delete has been signaled,
      // this decision is left to model instances.

      this.eachRelationship((key, meta) => {
        if (meta.kind === 'belongsTo') {
          this.notifyPropertyChange(key);
        }
      });
      LEGACY_SUPPORT.get(this)?.destroy();
      LEGACY_SUPPORT.delete(this);
      LEGACY_SUPPORT.delete(identifier);
      super.destroy();
    }

    /**
      If this property is `true` the record is in the `empty`
      state. Empty is the first state all records enter after they have
      been created. Most records created by the store will quickly
      transition to the `loading` state if data needs to be fetched from
      the server or the `created` state if the record is created on the
      client. A record can also enter the empty state if the adapter is
      unable to locate the record.
       @property isEmpty
      @public
      @type {Boolean}
      @readOnly
    */
    get isEmpty() {
      return this.currentState.isEmpty;
    }

    /**
      If this property is `true` the record is in the `loading` state. A
      record enters this state when the store asks the adapter for its
      data. It remains in this state until the adapter provides the
      requested data.
       @property isLoading
      @public
      @type {Boolean}
      @readOnly
    */
    get isLoading() {
      return this.currentState.isLoading;
    }

    /**
      If this property is `true` the record is in the `loaded` state. A
      record enters this state when its data is populated. Most of a
      record's lifecycle is spent inside substates of the `loaded`
      state.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.isLoaded; // true
       store.findRecord('model', 1).then(function(model) {
        model.isLoaded; // true
      });
      ```
       @property isLoaded
      @public
      @type {Boolean}
      @readOnly
    */
    get isLoaded() {
      return this.currentState.isLoaded;
    }

    /**
      If this property is `true` the record is in the `dirty` state. The
      record has local changes that have not yet been saved by the
      adapter. This includes records that have been created (but not yet
      saved) or deleted.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.hasDirtyAttributes; // true
       store.findRecord('model', 1).then(function(model) {
        model.hasDirtyAttributes; // false
        model.set('foo', 'some value');
        model.hasDirtyAttributes; // true
      });
      ```
       @since 1.13.0
      @property hasDirtyAttributes
      @public
      @type {Boolean}
      @readOnly
    */
    get hasDirtyAttributes() {
      return this.currentState.isDirty;
    }

    /**
      If this property is `true` the record is in the `saving` state. A
      record enters the saving state when `save` is called, but the
      adapter has not yet acknowledged that the changes have been
      persisted to the backend.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.isSaving; // false
      let promise = record.save();
      record.isSaving; // true
      promise.then(function() {
        record.isSaving; // false
      });
      ```
       @property isSaving
      @public
      @type {Boolean}
      @readOnly
    */
    get isSaving() {
      return this.currentState.isSaving;
    }

    /**
      If this property is `true` the record is in the `deleted` state
      and has been marked for deletion. When `isDeleted` is true and
      `hasDirtyAttributes` is true, the record is deleted locally but the deletion
      was not yet persisted. When `isSaving` is true, the change is
      in-flight. When both `hasDirtyAttributes` and `isSaving` are false, the
      change has persisted.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.isDeleted;    // false
      record.deleteRecord();
       // Locally deleted
      record.isDeleted;           // true
      record.hasDirtyAttributes;  // true
      record.isSaving;            // false
       // Persisting the deletion
      let promise = record.save();
      record.isDeleted;    // true
      record.isSaving;     // true
       // Deletion Persisted
      promise.then(function() {
        record.isDeleted;          // true
        record.isSaving;           // false
        record.hasDirtyAttributes; // false
      });
      ```
       @property isDeleted
      @public
      @type {Boolean}
      @readOnly
    */
    get isDeleted() {
      return this.currentState.isDeleted;
    }

    /**
      If this property is `true` the record is in the `new` state. A
      record will be in the `new` state when it has been created on the
      client and the adapter has not yet report that it was successfully
      saved.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.isNew; // true
       record.save().then(function(model) {
        model.isNew; // false
      });
      ```
       @property isNew
      @public
      @type {Boolean}
      @readOnly
    */
    get isNew() {
      return this.currentState.isNew;
    }

    /**
      If this property is `true` the record is in the `valid` state.
       A record will be in the `valid` state when the adapter did not report any
      server-side validation failures.
       @property isValid
      @public
      @type {Boolean}
      @readOnly
    */
    get isValid() {
      return this.currentState.isValid;
    }

    /**
      If the record is in the dirty state this property will report what
      kind of change has caused it to move into the dirty
      state. Possible values are:
       - `created` The record has been created by the client and not yet saved to the adapter.
      - `updated` The record has been updated by the client and not yet saved to the adapter.
      - `deleted` The record has been deleted by the client and not yet saved to the adapter.
       Example
       ```javascript
      let record = store.createRecord('model');
      record.dirtyType; // 'created'
      ```
       @property dirtyType
      @public
      @type {String}
      @readOnly
    */
    get dirtyType() {
      return this.currentState.dirtyType;
    }

    /**
      If `true` the adapter reported that it was unable to save local
      changes to the backend for any reason other than a server-side
      validation error.
       Example
       ```javascript
      record.isError; // false
      record.set('foo', 'valid value');
      record.save().then(null, function() {
        record.isError; // true
      });
      ```
       @property isError
      @public
      @type {Boolean}
      @readOnly
    */
    get isError() {
      return this.currentState.isError;
    }
    set isError(v) {}
    /**
      All ember models have an id property. This is an identifier
      managed by an external source. These are always coerced to be
      strings before being used internally. Note when declaring the
      attributes for a model it is an error to declare an id
      attribute.
       ```javascript
      let record = store.createRecord('model');
      record.id; // null
       store.findRecord('model', 1).then(function(model) {
        model.id; // '1'
      });
      ```
       @property id
      @public
      @type {String}
    */
    get id() {
      // this guard exists, because some dev-only deprecation code
      // (addListener via validatePropertyInjections) invokes toString before the
      // object is real.

      return (0, _store.recordIdentifierFor)(this).id;
    }
    set id(id) {
      const normalizedId = (0, _private.coerceId)(id);
      const identifier = (0, _store.recordIdentifierFor)(this);
      let didChange = normalizedId !== identifier.id;
      (false && !(!didChange || identifier.id === null) && (0, _debug.assert)(`Cannot set ${identifier.type} record's id to ${id}, because id is already ${identifier.id}`, !didChange || identifier.id === null));
      if (normalizedId !== null && didChange) {
        this.store._instanceCache.setRecordId(identifier, normalizedId);
        this.store.notifications.notify(identifier, 'identity');
      }
    }
    toString() {
      return `<model::${this.constructor.modelName}:${this.id}>`;
    }

    /**
      @property currentState
      @private
      @type {Object}
    */
    // TODO we can probably make this a computeOnce
    // we likely do not need to notify the currentState root anymore
    get currentState() {
      // descriptors are called with the wrong `this` context during mergeMixins
      // when using legacy/classic ember classes. Basically: lazy in prod and eager in dev.
      // so we do this to try to steer folks to the nicer "dont user currentState"
      // error.
      {
        if (!this.___recordState) {
          this.___recordState = new RecordState(this);
        }
      }
      return this.___recordState;
    }
    set currentState(_v) {
      throw new Error('cannot set currentState');
    }

    /**
      The store service instance which created this record instance
      @property store
      @public
     */

    /**
      When the record is in the `invalid` state this object will contain
      any errors returned by the adapter. When present the errors hash
      contains keys corresponding to the invalid property names
      and values which are arrays of Javascript objects with two keys:
       - `message` A string containing the error message from the backend
      - `attribute` The name of the property associated with this error message
       ```javascript
      record.errors.length; // 0
      record.set('foo', 'invalid value');
      record.save().catch(function() {
        record.errors.foo;
        // [{message: 'foo should be a number.', attribute: 'foo'}]
      });
      ```
       The `errors` property is useful for displaying error messages to
      the user.
       ```handlebars
      <label>Username: <Input @value={{@model.username}} /> </label>
      {{#each @model.errors.username as |error|}}
        <div class="error">
          {{error.message}}
        </div>
      {{/each}}
      <label>Email: <Input @value={{@model.email}} /> </label>
      {{#each @model.errors.email as |error|}}
        <div class="error">
          {{error.message}}
        </div>
      {{/each}}
      ```
        You can also access the special `messages` property on the error
      object to get an array of all the error strings.
       ```handlebars
      {{#each @model.errors.messages as |message|}}
        <div class="error">
          {{message}}
        </div>
      {{/each}}
      ```
       @property errors
      @public
      @type {Errors}
    */
    get errors() {
      let errors = Errors.create({
        __record: this
      });
      this.currentState.updateInvalidErrors(errors);
      return errors;
    }

    /**
      This property holds the `AdapterError` object with which
      last adapter operation was rejected.
       @property adapterError
      @public
      @type {AdapterError}
    */
    get adapterError() {
      return this.currentState.adapterError;
    }
    set adapterError(v) {
      throw new Error(`adapterError is not directly settable`);
    }

    /**
      Create a JSON representation of the record, using the serialization
      strategy of the store's adapter.
      `serialize` takes an optional hash as a parameter, currently
      supported options are:
      - `includeId`: `true` if the record's ID should be included in the
        JSON representation.
       @method serialize
      @public
      @param {Object} options
      @return {Object} an object whose values are primitive JSON values only
    */
    serialize(options) {
      return (0, _store.storeFor)(this).serializeRecord(this, options);
    }

    /*
      We hook the default implementation to ensure
      our tagged properties are properly notified
      as well. We still super for everything because
      sync observers require a direct call occuring
      to trigger their flush. We wouldn't need to
      super in 4.0+ where sync observers are removed.
     */
    notifyPropertyChange(key) {
      let tag = peekTag(this, key);
      if (tag) {
        tag.notify();
      }
      super.notifyPropertyChange(key);
    }

    /**
      Marks the record as deleted but does not save it. You must call
      `save` afterwards if you want to persist it. You might use this
      method if you want to allow the user to still `rollbackAttributes()`
      after a delete was made.
       Example
       ```app/controllers/model/delete.js
      import Controller from '@ember/controller';
      import { action } from '@ember/object';
       export default class ModelDeleteController extends Controller {
        @action
        softDelete() {
          this.model.deleteRecord();
        }
         @action
        confirm() {
          this.model.save();
        }
         @action
        undo() {
          this.model.rollbackAttributes();
        }
      }
      ```
       @method deleteRecord
      @public
    */
    deleteRecord() {
      // ensure we've populated currentState prior to deleting a new record
      if (this.currentState) {
        (0, _store.storeFor)(this).deleteRecord(this);
      }
    }

    /**
      Same as `deleteRecord`, but saves the record immediately.
       Example
       ```app/controllers/model/delete.js
      import Controller from '@ember/controller';
      import { action } from '@ember/object';
       export default class ModelDeleteController extends Controller {
        @action
        delete() {
          this.model.destroyRecord().then(function() {
            this.transitionToRoute('model.index');
          });
        }
      }
      ```
       If you pass an object on the `adapterOptions` property of the options
      argument it will be passed to your adapter via the snapshot
       ```js
      record.destroyRecord({ adapterOptions: { subscribe: false } });
      ```
       ```app/adapters/post.js
      import MyCustomAdapter from './custom-adapter';
       export default class PostAdapter extends MyCustomAdapter {
        deleteRecord(store, type, snapshot) {
          if (snapshot.adapterOptions.subscribe) {
            // ...
          }
          // ...
        }
      }
      ```
       @method destroyRecord
      @public
      @param {Object} options
      @return {Promise} a promise that will be resolved when the adapter returns
      successfully or rejected if the adapter returns with an error.
    */
    destroyRecord(options) {
      const {
        isNew
      } = this.currentState;
      this.deleteRecord();
      if (isNew) {
        return Promise.resolve(this);
      }
      return this.save(options).then(_ => {
        this.unloadRecord();
        return this;
      });
    }

    /**
      Unloads the record from the store. This will not send a delete request
      to your server, it just unloads the record from memory.
       @method unloadRecord
      @public
    */
    unloadRecord() {
      if (this.currentState.isNew && (this.isDestroyed || this.isDestroying)) {
        return;
      }
      (0, _store.storeFor)(this).unloadRecord(this);
    }

    /**
      @method _notifyProperties
      @private
    */
    _notifyProperties(keys) {
      // changeProperties defers notifications until after the delegate
      // and protects with a try...finally block
      // previously used begin...endPropertyChanges but this is private API
      changeProperties(() => {
        let key;
        for (let i = 0, length = keys.length; i < length; i++) {
          key = keys[i];
          this.notifyPropertyChange(key);
        }
      });
    }

    /**
      Returns an object, whose keys are changed properties, and value is
      an [oldProp, newProp] array.
       The array represents the diff of the canonical state with the local state
      of the model. Note: if the model is created locally, the canonical state is
      empty since the adapter hasn't acknowledged the attributes yet:
       Example
       ```app/models/mascot.js
      import Model, { attr } from '@ember-data/model';
       export default class MascotModel extends Model {
        @attr('string') name;
        @attr('boolean', {
          defaultValue: false
        })
        isAdmin;
      }
      ```
       ```javascript
      let mascot = store.createRecord('mascot');
       mascot.changedAttributes(); // {}
       mascot.set('name', 'Tomster');
      mascot.changedAttributes(); // { name: [undefined, 'Tomster'] }
       mascot.set('isAdmin', true);
      mascot.changedAttributes(); // { isAdmin: [undefined, true], name: [undefined, 'Tomster'] }
       mascot.save().then(function() {
        mascot.changedAttributes(); // {}
         mascot.set('isAdmin', false);
        mascot.changedAttributes(); // { isAdmin: [true, false] }
      });
      ```
       @method changedAttributes
      @public
      @return {Object} an object, whose keys are changed properties,
        and value is an [oldProp, newProp] array.
    */
    changedAttributes() {
      return (0, _private.peekCache)(this).changedAttrs((0, _store.recordIdentifierFor)(this));
    }

    /**
      If the model `hasDirtyAttributes` this function will discard any unsaved
      changes. If the model `isNew` it will be removed from the store.
       Example
       ```javascript
      record.name; // 'Untitled Document'
      record.set('name', 'Doc 1');
      record.name; // 'Doc 1'
      record.rollbackAttributes();
      record.name; // 'Untitled Document'
      ```
       @since 1.13.0
      @method rollbackAttributes
      @public
    */
    rollbackAttributes() {
      const {
        currentState
      } = this;
      const {
        isNew
      } = currentState;
      (0, _store.storeFor)(this)._join(() => {
        (0, _private.peekCache)(this).rollbackAttrs((0, _store.recordIdentifierFor)(this));
        this.errors.clear();
        currentState.cleanErrorRequests();
        if (isNew) {
          this.unloadRecord();
        }
      });
    }

    /**
      @method _createSnapshot
      @private
    */
    // TODO @deprecate in favor of a public API or examples of how to test successfully
    _createSnapshot() {
      const store = (0, _store.storeFor)(this);
      if (!store._fetchManager) {
        const FetchManager = (0, _esCompat.default)(require("@ember-data/legacy-compat/-private")).FetchManager;
        store._fetchManager = new FetchManager(store);
      }
      return store._fetchManager.createSnapshot((0, _store.recordIdentifierFor)(this));
    }

    /**
      Save the record and persist any changes to the record to an
      external source via the adapter.
       Example
       ```javascript
      record.set('name', 'Tomster');
      record.save().then(function() {
        // Success callback
      }, function() {
        // Error callback
      });
      ```
      If you pass an object using the `adapterOptions` property of the options
     argument it will be passed to your adapter via the snapshot.
       ```js
      record.save({ adapterOptions: { subscribe: false } });
      ```
       ```app/adapters/post.js
      import MyCustomAdapter from './custom-adapter';
       export default class PostAdapter extends MyCustomAdapter {
        updateRecord(store, type, snapshot) {
          if (snapshot.adapterOptions.subscribe) {
            // ...
          }
          // ...
        }
      }
      ```
       @method save
      @public
      @param {Object} options
      @return {Promise} a promise that will be resolved when the adapter returns
      successfully or rejected if the adapter returns with an error.
    */
    save(options) {
      let promise;
      if (this.currentState.isNew && this.currentState.isDeleted) {
        promise = Promise.resolve(this);
      } else {
        this.errors.clear();
        promise = (0, _store.storeFor)(this).saveRecord(this, options);
      }
      return promise;
    }

    /**
      Reload the record from the adapter.
       This will only work if the record has already finished loading.
       Example
       ```app/controllers/model/view.js
      import Controller from '@ember/controller';
      import { action } from '@ember/object';
       export default class ViewController extends Controller {
        @action
        reload() {
          this.model.reload().then(function(model) {
          // do something with the reloaded model
          });
        }
      }
      ```
       @method reload
      @public
      @param {Object} options optional, may include `adapterOptions` hash which will be passed to adapter request
      @return {Promise} a promise that will be resolved with the record when the
      adapter returns successfully or rejected if the adapter returns
      with an error.
    */
    reload() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      options.isReloading = true;
      options.reload = true;
      const identifier = (0, _store.recordIdentifierFor)(this);
      (false && !(identifier.id) && (0, _debug.assert)(`You cannot reload a record without an ID`, identifier.id));
      this.isReloading = true;
      const promise = (0, _store.storeFor)(this).request({
        op: 'findRecord',
        data: {
          options,
          record: identifier
        },
        cacheOptions: {
          [Symbol.for('ember-data:skip-cache')]: true
        }
      }).then(() => this).finally(() => {
        this.isReloading = false;
      });
      return promise;
    }
    attr() {
      (false && !(false) && (0, _debug.assert)('The `attr` method is not available on Model, a Snapshot was probably expected. Are you passing a Model instead of a Snapshot to your serializer?', false));
    }

    /**
      Get the reference for the specified belongsTo relationship.
       Example
       ```app/models/blog.js
      import Model, { belongsTo } from '@ember-data/model';
       export default class BlogModel extends Model {
        @belongsTo('user', { async: true, inverse: null }) user;
      }
      ```
       ```javascript
      let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            user: {
              data: { type: 'user', id: 1 }
            }
          }
        }
      });
      let userRef = blog.belongsTo('user');
       // check if the user relationship is loaded
      let isLoaded = userRef.value() !== null;
       // get the record of the reference (null if not yet available)
      let user = userRef.value();
       // get the identifier of the reference
      if (userRef.remoteType() === "id") {
        let id = userRef.id();
      } else if (userRef.remoteType() === "link") {
        let link = userRef.link();
      }
       // load user (via store.findRecord or store.findBelongsTo)
      userRef.load().then(...)
       // or trigger a reload
      userRef.reload().then(...)
       // provide data for reference
      userRef.push({
        type: 'user',
        id: 1,
        attributes: {
          username: "@user"
        }
      }).then(function(user) {
        userRef.value() === user;
      });
      ```
       @method belongsTo
      @public
      @param {String} name of the relationship
      @since 2.5.0
      @return {BelongsToReference} reference for this relationship
    */
    belongsTo(name) {
      return lookupLegacySupport(this).referenceFor('belongsTo', name);
    }

    /**
      Get the reference for the specified hasMany relationship.
       Example
       ```app/models/blog.js
      import Model, { hasMany } from '@ember-data/model';
       export default class BlogModel extends Model {
        @hasMany('comment', { async: true, inverse: null }) comments;
      }
       let blog = store.push({
        data: {
          type: 'blog',
          id: 1,
          relationships: {
            comments: {
              data: [
                { type: 'comment', id: 1 },
                { type: 'comment', id: 2 }
              ]
            }
          }
        }
      });
      let commentsRef = blog.hasMany('comments');
       // check if the comments are loaded already
      let isLoaded = commentsRef.value() !== null;
       // get the records of the reference (null if not yet available)
      let comments = commentsRef.value();
       // get the identifier of the reference
      if (commentsRef.remoteType() === "ids") {
        let ids = commentsRef.ids();
      } else if (commentsRef.remoteType() === "link") {
        let link = commentsRef.link();
      }
       // load comments (via store.findMany or store.findHasMany)
      commentsRef.load().then(...)
       // or trigger a reload
      commentsRef.reload().then(...)
       // provide data for reference
      commentsRef.push([{ type: 'comment', id: 1 }, { type: 'comment', id: 2 }]).then(function(comments) {
        commentsRef.value() === comments;
      });
      ```
       @method hasMany
      @public
      @param {String} name of the relationship
      @since 2.5.0
      @return {HasManyReference} reference for this relationship
    */
    hasMany(name) {
      return lookupLegacySupport(this).referenceFor('hasMany', name);
    }

    /**
     Given a callback, iterates over each of the relationships in the model,
     invoking the callback with the name of each relationship and its relationship
     descriptor.
       The callback method you provide should have the following signature (all
     parameters are optional):
      ```javascript
     function(name, descriptor);
     ```
      - `name` the name of the current property in the iteration
     - `descriptor` the meta object that describes this relationship
      The relationship descriptor argument is an object with the following properties.
      - **key** <span class="type">String</span> the name of this relationship on the Model
     - **kind** <span class="type">String</span> "hasMany" or "belongsTo"
     - **options** <span class="type">Object</span> the original options hash passed when the relationship was declared
     - **parentType** <span class="type">Model</span> the type of the Model that owns this relationship
     - **type** <span class="type">String</span> the type name of the related Model
      Note that in addition to a callback, you can also pass an optional target
     object that will be set as `this` on the context.
      Example
      ```app/serializers/application.js
     import JSONSerializer from '@ember-data/serializer/json';
      export default class ApplicationSerializer extends JSONSerializer {
        serialize(record, options) {
        let json = {};
         record.eachRelationship(function(name, descriptor) {
          if (descriptor.kind === 'hasMany') {
            let serializedHasManyName = name.toUpperCase() + '_IDS';
            json[serializedHasManyName] = record.get(name).map(r => r.id);
          }
        });
         return json;
      }
    }
     ```
      @method eachRelationship
      @public
     @param {Function} callback the callback to invoke
     @param {any} binding the value to which the callback's `this` should be bound
     */
    eachRelationship(callback, binding) {
      this.constructor.eachRelationship(callback, binding);
    }
    relationshipFor(name) {
      return this.constructor.relationshipsByName.get(name);
    }
    inverseFor(key) {
      return this.constructor.inverseFor(key, (0, _store.storeFor)(this));
    }
    eachAttribute(callback, binding) {
      this.constructor.eachAttribute(callback, binding);
    }
    /*
     These class methods below provide relationship
     introspection abilities about relationships.
      A note about the computed properties contained here:
      **These properties are effectively sealed once called for the first time.**
     To avoid repeatedly doing expensive iteration over a model's fields, these
     values are computed once and then cached for the remainder of the runtime of
     your application.
      If your application needs to modify a class after its initial definition
     (for example, using `reopen()` to add additional attributes), make sure you
     do it before using your model with the store, which uses these properties
     extensively.
     */
    /**
     For a given relationship name, returns the model type of the relationship.
      For example, if you define a model like this:
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
       @hasMany('comment') comments;
     }
     ```
      Calling `store.modelFor('post').typeForRelationship('comments', store)` will return `Comment`.
      @method typeForRelationship
      @public
     @static
     @param {String} name the name of the relationship
     @param {store} store an instance of Store
     @return {Model} the type of the relationship, or undefined
     */
    static typeForRelationship(name, store) {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let relationship = this.relationshipsByName.get(name);
      return relationship && store.modelFor(relationship.type);
    }
    static get inverseMap() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      return Object.create(null);
    }

    /**
     Find the relationship which is the inverse of the one asked for.
      For example, if you define models like this:
      ```app/models/post.js
     import Model, { hasMany } from '@ember-data/model';
      export default class PostModel extends Model {
        @hasMany('message') comments;
      }
     ```
      ```app/models/message.js
     import Model, { belongsTo } from '@ember-data/model';
      export default class MessageModel extends Model {
        @belongsTo('post') owner;
      }
     ```
      ``` js
     store.modelFor('post').inverseFor('comments', store) // { type: App.Message, name: 'owner', kind: 'belongsTo' }
     store.modelFor('message').inverseFor('owner', store) // { type: App.Post, name: 'comments', kind: 'hasMany' }
     ```
      @method inverseFor
      @public
     @static
     @param {String} name the name of the relationship
     @param {Store} store
     @return {Object} the inverse relationship, or null
     */
    static inverseFor(name, store) {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let inverseMap = this.inverseMap;
      if (inverseMap[name]) {
        return inverseMap[name];
      } else {
        let inverse = this._findInverseFor(name, store);
        inverseMap[name] = inverse;
        return inverse;
      }
    }

    //Calculate the inverse, ignoring the cache
    static _findInverseFor(name, store) {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      const relationship = this.relationshipsByName.get(name);
      const {
        options
      } = relationship;
      const isPolymorphic = options.polymorphic;

      //If inverse is manually specified to be null, like  `comments: hasMany('message', { inverse: null })`
      const isExplicitInverseNull = options.inverse === null;
      const isAbstractType = !isExplicitInverseNull && isPolymorphic && !store.getSchemaDefinitionService().doesTypeExist(relationship.type);
      if (isExplicitInverseNull || isAbstractType) {
        (false && !(!isPolymorphic || isExplicitInverseNull) && (0, _debug.assert)(`No schema for the abstract type '${relationship.type}' for the polymorphic relationship '${name}' on '${this.modelName}' was provided by the SchemaDefinitionService.`, !isPolymorphic || isExplicitInverseNull));
        return null;
      }
      let fieldOnInverse, inverseKind, inverseRelationship, inverseOptions;
      let inverseSchema = this.typeForRelationship(name, store);

      // if the type does not exist and we are not polymorphic
      //If inverse is specified manually, return the inverse
      if (options.inverse !== undefined) {
        fieldOnInverse = options.inverse;
        inverseRelationship = inverseSchema && inverseSchema.relationshipsByName.get(fieldOnInverse);
        (false && !(inverseRelationship) && (0, _debug.assert)(`We found no field named '${fieldOnInverse}' on the schema for '${inverseSchema.modelName}' to be the inverse of the '${name}' relationship on '${this.modelName}'. This is most likely due to a missing field on your model definition.`, inverseRelationship)); // TODO probably just return the whole inverse here
        inverseKind = inverseRelationship.kind;
        inverseOptions = inverseRelationship.options;
      } else {
        //No inverse was specified manually, we need to use a heuristic to guess one
        if (relationship.type === relationship.parentModelName) {
          (false && (0, _debug.warn)(`Detected a reflexive relationship named '${name}' on the schema for '${relationship.type}' without an inverse option. Look at https://guides.emberjs.com/current/models/relationships/#toc_reflexive-relations for how to explicitly specify inverses.`, false, {
            id: 'ds.model.reflexive-relationship-without-inverse'
          }));
        }
        let possibleRelationships = findPossibleInverses(this, inverseSchema, name);
        if (possibleRelationships.length === 0) {
          return null;
        }
        let explicitRelationship = possibleRelationships.find(relationship => relationship.options.inverse === name);
        if (explicitRelationship) {
          possibleRelationships = [explicitRelationship];
        }
        (false && !(possibleRelationships.length === 1) && (0, _debug.assert)("You defined the '" + name + "' relationship on " + this + ', but multiple possible inverse relationships of type ' + this + ' were found on ' + inverseSchema + '. Look at https://guides.emberjs.com/current/models/relationships/#toc_explicit-inverses for how to explicitly specify inverses', possibleRelationships.length === 1));
        fieldOnInverse = possibleRelationships[0].name;
        inverseKind = possibleRelationships[0].kind;
        inverseOptions = possibleRelationships[0].options;
      }

      // ensure inverse is properly configured

      // ensure we are properly configured
      (false && !(inverseOptions.inverse !== null) && (0, _debug.assert)(`The ${inverseSchema.modelName}:${fieldOnInverse} relationship declares 'inverse: null', but it was resolved as the inverse for ${this.modelName}:${name}.`, inverseOptions.inverse !== null));
      return {
        type: inverseSchema,
        name: fieldOnInverse,
        kind: inverseKind,
        options: inverseOptions
      };
    }

    /**
     The model's relationships as a map, keyed on the type of the
     relationship. The value of each entry is an array containing a descriptor
     for each relationship with that type, describing the name of the relationship
     as well as the type.
      For example, given the following model definition:
      ```app/models/blog.js
     import Model, { belongsTo, hasMany } from '@ember-data/model';
      export default class BlogModel extends Model {
        @hasMany('user') users;
        @belongsTo('user') owner;
        @hasMany('post') posts;
      }
     ```
      This computed property would return a map describing these
     relationships, like this:
      ```javascript
     import { get } from '@ember/object';
     import Blog from 'app/models/blog';
     import User from 'app/models/user';
     import Post from 'app/models/post';
      let relationships = Blog.relationships;
     relationships.user;
     //=> [ { name: 'users', kind: 'hasMany' },
     //     { name: 'owner', kind: 'belongsTo' } ]
     relationships.post;
     //=> [ { name: 'posts', kind: 'hasMany' } ]
     ```
      @property relationships
      @public
     @static
     @type Map
     @readOnly
     */

    static get relationships() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let map = new Map();
      let relationshipsByName = this.relationshipsByName;

      // Loop through each computed property on the class
      relationshipsByName.forEach(desc => {
        let {
          type
        } = desc;
        if (!map.has(type)) {
          map.set(type, []);
        }
        map.get(type).push(desc);
      });
      return map;
    }

    /**
     A hash containing lists of the model's relationships, grouped
     by the relationship kind. For example, given a model with this
     definition:
      ```app/models/blog.js
     import Model, { belongsTo, hasMany } from '@ember-data/model';
      export default class BlogModel extends Model {
        @hasMany('user') users;
        @belongsTo('user') owner;
         @hasMany('post') posts;
      }
     ```
      This property would contain the following:
      ```javascript
     import { get } from '@ember/object';
     import Blog from 'app/models/blog';
      let relationshipNames = Blog.relationshipNames;
     relationshipNames.hasMany;
     //=> ['users', 'posts']
     relationshipNames.belongsTo;
     //=> ['owner']
     ```
      @property relationshipNames
      @public
     @static
     @type Object
     @readOnly
     */
    static get relationshipNames() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let names = {
        hasMany: [],
        belongsTo: []
      };
      this.eachComputedProperty((name, meta) => {
        if (meta.isRelationship) {
          names[meta.kind].push(name);
        }
      });
      return names;
    }

    /**
     An array of types directly related to a model. Each type will be
     included once, regardless of the number of relationships it has with
     the model.
      For example, given a model with this definition:
      ```app/models/blog.js
     import Model, { belongsTo, hasMany } from '@ember-data/model';
      export default class BlogModel extends Model {
        @hasMany('user') users;
        @belongsTo('user') owner;
         @hasMany('post') posts;
      }
     ```
      This property would contain the following:
      ```javascript
     import { get } from '@ember/object';
     import Blog from 'app/models/blog';
      let relatedTypes = Blog.relatedTypes');
     //=> ['user', 'post']
     ```
      @property relatedTypes
      @public
     @static
     @type Ember.Array
     @readOnly
     */
    static get relatedTypes() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let types = [];
      let rels = this.relationshipsObject;
      let relationships = Object.keys(rels);

      // create an array of the unique types involved
      // in relationships
      for (let i = 0; i < relationships.length; i++) {
        let name = relationships[i];
        let meta = rels[name];
        let modelName = meta.type;
        if (types.indexOf(modelName) === -1) {
          types.push(modelName);
        }
      }
      return types;
    }

    /**
     A map whose keys are the relationships of a model and whose values are
     relationship descriptors.
      For example, given a model with this
     definition:
      ```app/models/blog.js
     import Model, { belongsTo, hasMany } from '@ember-data/model';
      export default class BlogModel extends Model {
        @hasMany('user') users;
        @belongsTo('user') owner;
         @hasMany('post') posts;
      }
     ```
      This property would contain the following:
      ```javascript
     import { get } from '@ember/object';
     import Blog from 'app/models/blog';
      let relationshipsByName = Blog.relationshipsByName;
     relationshipsByName.users;
     //=> { key: 'users', kind: 'hasMany', type: 'user', options: Object, isRelationship: true }
     relationshipsByName.owner;
     //=> { key: 'owner', kind: 'belongsTo', type: 'user', options: Object, isRelationship: true }
     ```
      @property relationshipsByName
      @public
     @static
     @type Map
     @readOnly
     */
    static get relationshipsByName() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let map = new Map();
      let rels = this.relationshipsObject;
      let relationships = Object.keys(rels);
      for (let i = 0; i < relationships.length; i++) {
        let key = relationships[i];
        let value = rels[key];
        map.set(value.name || value.key, value);
      }
      return map;
    }
    static get relationshipsObject() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let relationships = Object.create(null);
      let modelName = this.modelName;
      this.eachComputedProperty((name, meta) => {
        if (meta.isRelationship) {
          meta.key = name;
          meta.name = name;
          meta.parentModelName = modelName;
          relationships[name] = meta;
          (false && !(!(meta.options.inverse === null && meta.options.as?.length > 0)) && (0, _debug.assert)(`You should not specify both options.as and options.inverse as null on ${modelName}.${meta.name}, as if there is no inverse field there is no abstract type to conform to. You may have intended for this relationship to be polymorphic, or you may have mistakenly set inverse to null.`, !(meta.options.inverse === null && meta.options.as?.length > 0)));
        }
      });
      return relationships;
    }

    /**
     A map whose keys are the fields of the model and whose values are strings
     describing the kind of the field. A model's fields are the union of all of its
     attributes and relationships.
      For example:
      ```app/models/blog.js
     import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
      export default class BlogModel extends Model {
        @hasMany('user') users;
        @belongsTo('user') owner;
         @hasMany('post') posts;
         @attr('string') title;
      }
     ```
      ```js
     import { get } from '@ember/object';
     import Blog from 'app/models/blog'
      let fields = Blog.fields;
     fields.forEach(function(kind, field) {
        // do thing
      });
      // prints:
     // users, hasMany
     // owner, belongsTo
     // posts, hasMany
     // title, attribute
     ```
      @property fields
      @public
     @static
     @type Map
     @readOnly
     */
    static get fields() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let map = new Map();
      this.eachComputedProperty((name, meta) => {
        // TODO end reliance on these booleans and stop leaking them in the spec
        if (meta.isRelationship) {
          map.set(name, meta.kind);
        } else if (meta.isAttribute) {
          map.set(name, 'attribute');
        }
      });
      return map;
    }

    /**
     Given a callback, iterates over each of the relationships in the model,
     invoking the callback with the name of each relationship and its relationship
     descriptor.
      @method eachRelationship
      @public
     @static
     @param {Function} callback the callback to invoke
     @param {any} binding the value to which the callback's `this` should be bound
     */
    static eachRelationship(callback, binding) {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      this.relationshipsByName.forEach((relationship, name) => {
        callback.call(binding, name, relationship);
      });
    }

    /**
     Given a callback, iterates over each of the types related to a model,
     invoking the callback with the related type's class. Each type will be
     returned just once, regardless of how many different relationships it has
     with a model.
      @method eachRelatedType
      @public
     @static
     @param {Function} callback the callback to invoke
     @param {any} binding the value to which the callback's `this` should be bound
     */
    static eachRelatedType(callback, binding) {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let relationshipTypes = this.relatedTypes;
      for (let i = 0; i < relationshipTypes.length; i++) {
        let type = relationshipTypes[i];
        callback.call(binding, type);
      }
    }
    static determineRelationshipType(knownSide, store) {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let knownKey = knownSide.key;
      let knownKind = knownSide.kind;
      let inverse = this.inverseFor(knownKey, store);
      // let key;
      let otherKind;
      if (!inverse) {
        return knownKind === 'belongsTo' ? 'oneToNone' : 'manyToNone';
      }

      // key = inverse.name;
      otherKind = inverse.kind;
      if (otherKind === 'belongsTo') {
        return knownKind === 'belongsTo' ? 'oneToOne' : 'manyToOne';
      } else {
        return knownKind === 'belongsTo' ? 'oneToMany' : 'manyToMany';
      }
    }

    /**
     A map whose keys are the attributes of the model (properties
     described by attr) and whose values are the meta object for the
     property.
      Example
      ```app/models/person.js
     import Model, { attr } from '@ember-data/model';
      export default class PersonModel extends Model {
        @attr('string') firstName;
        @attr('string') lastName;
        @attr('date') birthday;
      }
     ```
      ```javascript
     import { get } from '@ember/object';
     import Person from 'app/models/person'
      let attributes = Person.attributes
      attributes.forEach(function(meta, name) {
        // do thing
      });
      // prints:
     // firstName {type: "string", isAttribute: true, options: Object, parentType: function, name: "firstName"}
     // lastName {type: "string", isAttribute: true, options: Object, parentType: function, name: "lastName"}
     // birthday {type: "date", isAttribute: true, options: Object, parentType: function, name: "birthday"}
     ```
      @property attributes
      @public
     @static
     @type {Map}
     @readOnly
     */
    static get attributes() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let map = new Map();
      this.eachComputedProperty((name, meta) => {
        if (meta.isAttribute) {
          (false && !(name !== 'id') && (0, _debug.assert)("You may not set `id` as an attribute on your model. Please remove any lines that look like: `id: attr('<type>')` from " + this.toString(), name !== 'id'));
          meta.name = name;
          map.set(name, meta);
        }
      });
      return map;
    }

    /**
     A map whose keys are the attributes of the model (properties
     described by attr) and whose values are type of transformation
     applied to each attribute. This map does not include any
     attributes that do not have an transformation type.
      Example
      ```app/models/person.js
     import Model, { attr } from '@ember-data/model';
      export default class PersonModel extends Model {
        @attr firstName;
        @attr('string') lastName;
        @attr('date') birthday;
      }
     ```
      ```javascript
     import { get } from '@ember/object';
     import Person from 'app/models/person';
      let transformedAttributes = Person.transformedAttributes
      transformedAttributes.forEach(function(field, type) {
        // do thing
      });
      // prints:
     // lastName string
     // birthday date
     ```
      @property transformedAttributes
      @public
     @static
     @type {Map}
     @readOnly
     */
    static get transformedAttributes() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      let map = new Map();
      this.eachAttribute((key, meta) => {
        if (meta.type) {
          map.set(key, meta.type);
        }
      });
      return map;
    }

    /**
     Iterates through the attributes of the model, calling the passed function on each
     attribute.
      The callback method you provide should have the following signature (all
     parameters are optional):
      ```javascript
     function(name, meta);
     ```
      - `name` the name of the current property in the iteration
     - `meta` the meta object for the attribute property in the iteration
      Note that in addition to a callback, you can also pass an optional target
     object that will be set as `this` on the context.
      Example
      ```javascript
     import Model, { attr } from '@ember-data/model';
      class PersonModel extends Model {
        @attr('string') firstName;
        @attr('string') lastName;
        @attr('date') birthday;
      }
      PersonModel.eachAttribute(function(name, meta) {
        // do thing
      });
      // prints:
     // firstName {type: "string", isAttribute: true, options: Object, parentType: function, name: "firstName"}
     // lastName {type: "string", isAttribute: true, options: Object, parentType: function, name: "lastName"}
     // birthday {type: "date", isAttribute: true, options: Object, parentType: function, name: "birthday"}
     ```
      @method eachAttribute
      @public
     @param {Function} callback The callback to execute
     @param {Object} [binding] the value to which the callback's `this` should be bound
     @static
     */
    static eachAttribute(callback, binding) {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      this.attributes.forEach((meta, name) => {
        callback.call(binding, name, meta);
      });
    }

    /**
     Iterates through the transformedAttributes of the model, calling
     the passed function on each attribute. Note the callback will not be
     called for any attributes that do not have an transformation type.
      The callback method you provide should have the following signature (all
     parameters are optional):
      ```javascript
     function(name, type);
     ```
      - `name` the name of the current property in the iteration
     - `type` a string containing the name of the type of transformed
     applied to the attribute
      Note that in addition to a callback, you can also pass an optional target
     object that will be set as `this` on the context.
      Example
      ```javascript
     import Model, { attr } from '@ember-data/model';
      let Person = Model.extend({
        firstName: attr(),
        lastName: attr('string'),
        birthday: attr('date')
      });
      Person.eachTransformedAttribute(function(name, type) {
        // do thing
      });
      // prints:
     // lastName string
     // birthday date
     ```
      @method eachTransformedAttribute
      @public
     @param {Function} callback The callback to execute
     @param {Object} [binding] the value to which the callback's `this` should be bound
     @static
     */
    static eachTransformedAttribute(callback, binding) {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      this.transformedAttributes.forEach((type, name) => {
        callback.call(binding, name, type);
      });
    }

    /**
     Returns the name of the model class.
      @method toString
      @public
     @static
     */
    static toString() {
      (false && !(this.modelName) && (0, _debug.assert)(`Accessing schema information on Models without looking up the model via the store is disallowed.`, this.modelName));
      return `model:${this.modelName}`;
    }
  }, _class2.isModel = true, _class2.modelName = null, _class2), (_applyDecoratedDescriptor(_class.prototype, "isEmpty", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isEmpty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLoading", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isLoading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isLoaded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasDirtyAttributes", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "hasDirtyAttributes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSaving", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isSaving"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDeleted", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isDeleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isNew", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isValid", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dirtyType", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "dirtyType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isError", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "isError"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "isReloading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "id", [tagged], Object.getOwnPropertyDescriptor(_class.prototype, "id"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentState", [tagged], Object.getOwnPropertyDescriptor(_class.prototype, "currentState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "errors", [computeOnce], Object.getOwnPropertyDescriptor(_class.prototype, "errors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adapterError", [_compat.dependentKeyCompat], Object.getOwnPropertyDescriptor(_class.prototype, "adapterError"), _class.prototype), _applyDecoratedDescriptor(_class, "inverseMap", [computeOnce], Object.getOwnPropertyDescriptor(_class, "inverseMap"), _class), _applyDecoratedDescriptor(_class, "relationships", [computeOnce], Object.getOwnPropertyDescriptor(_class, "relationships"), _class), _applyDecoratedDescriptor(_class, "relationshipNames", [computeOnce], Object.getOwnPropertyDescriptor(_class, "relationshipNames"), _class), _applyDecoratedDescriptor(_class, "relatedTypes", [computeOnce], Object.getOwnPropertyDescriptor(_class, "relatedTypes"), _class), _applyDecoratedDescriptor(_class, "relationshipsByName", [computeOnce], Object.getOwnPropertyDescriptor(_class, "relationshipsByName"), _class), _applyDecoratedDescriptor(_class, "relationshipsObject", [computeOnce], Object.getOwnPropertyDescriptor(_class, "relationshipsObject"), _class), _applyDecoratedDescriptor(_class, "fields", [computeOnce], Object.getOwnPropertyDescriptor(_class, "fields"), _class), _applyDecoratedDescriptor(_class, "attributes", [computeOnce], Object.getOwnPropertyDescriptor(_class, "attributes"), _class), _applyDecoratedDescriptor(_class, "transformedAttributes", [computeOnce], Object.getOwnPropertyDescriptor(_class, "transformedAttributes"), _class)), _class); // this is required to prevent `init` from passing
  // the values initialized during create to `setUnknownProperty`
  Model.prototype._createProps = null;
  Model.prototype._secretInit = null;
});