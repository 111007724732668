define("ember-power-calendar/templates/components/power-calendar/nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav class="ember-power-calendar-nav">
    {{#if @calendar.actions.changeCenter}}
      <button type="button" class="ember-power-calendar-nav-control ember-power-calendar-nav-control--previous" {{on "click" (fn @calendar.actions.moveCenter -1 this.unit @calendar)}}>«</button>
    {{/if}}
    <div class="ember-power-calendar-nav-title">
      {{#if (has-block)}}
        {{yield @calendar}}
      {{else}}
        {{power-calendar-format-date @calendar.center this.format locale=@calendar.locale}}
      {{/if}}
    </div>
    {{#if @calendar.actions.changeCenter}}
      <button type="button" class="ember-power-calendar-nav-control ember-power-calendar-nav-control--next" {{on "click" (fn @calendar.actions.moveCenter 1 this.unit @calendar)}}>»</button>
    {{/if}}
  </nav>
  
  */
  {
    "id": "Hvg0h9ui",
    "block": "[[[10,\"nav\"],[14,0,\"ember-power-calendar-nav\"],[12],[1,\"\\n\"],[41,[30,1,[\"actions\",\"changeCenter\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control ember-power-calendar-nav-control--previous\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,1,[\"actions\",\"moveCenter\"]],-1,[30,0,[\"unit\"]],[30,1]],null]],null],[12],[1,\"«\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"ember-power-calendar-nav-title\"],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,5],[[30,1,[\"center\"]],[30,0,[\"format\"]]],[[\"locale\"],[[30,1,[\"locale\"]]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,1,[\"actions\",\"changeCenter\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control ember-power-calendar-nav-control--next\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,1,[\"actions\",\"moveCenter\"]],1,[30,0,[\"unit\"]],[30,1]],null]],null],[12],[1,\"»\"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@calendar\",\"&default\"],false,[\"if\",\"on\",\"fn\",\"has-block\",\"yield\",\"power-calendar-format-date\"]]",
    "moduleName": "ember-power-calendar/templates/components/power-calendar/nav.hbs",
    "isStrictMode": false
  });
});