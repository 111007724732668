define("@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr ...attributes>
    {{yield
      (hash cell=(
        component "ignite-paginated-data-table/-header/-cell"
        currentSort=@currentSort
        onSortChange=@onSortChange
      )
    )
    }}
  </tr>
  */
  {
    "id": "15X++TUy",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[18,4,[[28,[37,1],null,[[\"cell\"],[[50,\"ignite-paginated-data-table/-header/-cell\",0,null,[[\"currentSort\",\"onSortChange\"],[[30,2],[30,3]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@currentSort\",\"@onSortChange\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});