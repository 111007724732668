define("@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/modifiers/kb-article-container", ["exports", "ember-modifier", "@ember/destroyable", "@ember/service", "@ember/utils"], function (_exports, _emberModifier, _destroyable, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let KBArticleContainerModifier = _exports.default = (_class = class KBArticleContainerModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "blobDownload", _descriptor, this);
      _initializerDefineProperty(this, "errors", _descriptor2, this);
      _defineProperty(this, "element", undefined);
      _defineProperty(this, "event", undefined);
      _defineProperty(this, "handler", undefined);
      _defineProperty(this, "_host", void 0);
      _defineProperty(this, "_downloadErrorMessagePrefix", undefined);
      _defineProperty(this, "_downloadingPlaceholder", 'Downloading ...');
      _defineProperty(this, "_downloadingClassLink", undefined);
      _defineProperty(this, "addEventListener", (element, event, handler) => {
        this.element = element;
        this.event = event;
        this.handler = handler;
        element.addEventListener(event, handler);
      });
    }
    removeEventListener(instance) {
      let {
        element,
        event,
        handler
      } = instance;

      /* istanbul ignore else */
      if (element && event && handler) {
        element.removeEventListener(event, handler);
        instance.element = undefined;
        instance.event = undefined;
        instance.handler = undefined;
      }
    }
    modify(element, _, _ref) {
      let {
        host,
        downloadErrorMessagePrefix,
        downloadingPlaceholder,
        downloadingClassLink
      } = _ref;
      if (!host) {
        throw 'You must pass "host" argument to the kb-article-container modifier.';
      } else {
        this._host = host;
      }
      if (downloadErrorMessagePrefix) {
        this._downloadErrorMessagePrefix = downloadErrorMessagePrefix;
      }
      if (downloadingPlaceholder) {
        this._downloadingPlaceholder = downloadingPlaceholder;
      }
      if (downloadingClassLink) {
        this._downloadingClassLink = downloadingClassLink;
      }
      this.addEventListener(element, 'click', this.handleAuthenticatedLinkClick.bind(this));
      (0, _destroyable.registerDestructor)(this, this.removeEventListener);
    }
    async handleAuthenticatedLinkClick(e) {
      if (!e.ctrlKey && !e.metaKey) {
        // event target can be a child of the link
        let link = e.target.closest('[href]');
        if ((0, _utils.isNone)(link)) {
          return;
        }
        let linkHref = link.getAttribute('href');
        if (linkHref.startsWith(this._host)) {
          let linkText = link.innerHTML;

          // Removes the anchor's hyperlinking behaviour and replaces its text
          link.removeAttribute('href');
          link.innerHTML = this._downloadingPlaceholder;
          if (this._downloadingClassLink) {
            link.classList.add(this._downloadingClassLink);
          }
          try {
            await this.errors.catchActionErrors(this.blobDownload.downloadFile(linkHref), {
              messagePrefix: this._downloadErrorMessagePrefix,
              rethrow: true
            });
          } catch (e) {
            // ignore
          }

          // Restores the anchor's hyperlinking behaviour and the link's text
          link.setAttribute('href', linkHref);
          link.innerHTML = linkText;
          if (this._downloadingClassLink) {
            link.classList.remove(this._downloadingClassLink);
          }
          e.preventDefault();
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "blobDownload", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errors", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});