define("@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/helpers/patch-article-images", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class PatchArticleUrlsHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "withCustomParams", false);
    }
    get host() {
      throw new Error('You should override host attribute on PatchArticleUrlsHelper');
    }
    addToken() {
      throw new Error('You should override addToken method on PatchArticleUrlsHelper');
    }
    compute(_ref) {
      let [body] = _ref;
      if (!body) {
        return;
      }
      return body.replace(/\bsrc="([^"]+)"/g, (_, value) => {
        if (value.startsWith(this.host)) {
          return `src="${this.addToken(value)}"`;
        } else {
          return `src="${value}"`;
        }
      });
    }
  }
  _exports.default = PatchArticleUrlsHelper;
});