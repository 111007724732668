define("@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/helpers/find-article-version", ["exports", "@ember/component/helper", "@ember/service", "@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/models/article-base"], function (_exports, _helper, _service, _articleBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let FindArticleVersionHelper = _exports.default = (_class = class FindArticleVersionHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "localizedString", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "locales", _descriptor3, this);
      _defineProperty(this, "removeLocaleObserver", undefined);
      this.removeLocaleObserver = this.intl.onLocaleChanged(this.recompute, this);
    }
    compute(_ref) {
      let [article, locale = undefined] = _ref;
      let localeFound = false;
      let version = undefined;

      /* istanbul ignore if */
      if (!article || !(article instanceof _articleBase.default)) {
        throw new Error('You should pass an instance of an article model to the `find-article-version` helper');
      }
      let {
        locales: localesService
      } = this;
      let locales = locale ? [locale] : this.localizedString.fallbacks(article);
      for (let i = 0; i < locales.length && !version; i++) {
        version = article.versionsByLanguage[locales[i]]?.slice().at(-1);
        // return localeFound to true if version found is in current locale
        // or match locale internal fallback (eg. 'fr-fr' for 'fr-ca' locale)
        localeFound = version && (locale || version.language_code === localesService.locale || version.language_code === localesService.localesList[localesService.locale]?.fallback);
      }
      if (!version && !locale) {
        version = article.versions.slice().at(-1);
      }
      return {
        version,
        localeFound
      };
    }
    willDestroy() {
      this.removeLocaleObserver?.();
      super.willDestroy(...arguments);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "localizedString", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "locales", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});