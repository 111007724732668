define("ember-responsive/services/media", ["exports", "ember", "@glimmer/tracking", "@ember/runloop", "@ember/object", "@ember/service", "@ember/string", "ember-responsive/null-match-media", "@ember/application", "@ember/object/evented", "@ember/object/compat"], function (_exports, _ember, _tracking, _runloop, _object, _service, _string, _nullMatchMedia, _application, _evented, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
  * Handles detecting and responding to media queries.
  *
  * **Adding media query matchers**
  *
  * The first step to using the class is to add media queries that you
  * want it to listen to. Each media query has a name that you can
  * use to reference it by.
  *
  * ```javascript
  * media = Ember.Responsive.Media.create();
  * media.match('mobile', '(max-width: 767px)');
  * media.match('desktop', '(min-width: 768px)');
  * ```
  *
  * **Testing the media query matchers**
  *
  * Now that you've added a few matchers, you can access those media queries as
  * if they were properties on your object. The nice thing is that whenever the
  * media queries change, this class will automatically update the relevant
  * properties (and so will the rest of your application, thanks to the power
  * of two-way data-binding).
  *
  * ```javascript
  * media = Ember.Responsive.Media.create();
  * media.match('mobile', '(max-width: 767px)');
  * media.match('desktop', '(min-width: 768px)');
  *
  * // There are convenient "isser" properties defined...
  * if (media.get('isMobile')) {
  *   console.log('mobile!');
  * }
  *
  * // As well as access to the matchMedia API...
  * if (media.get('desktop.matches')) {
  *   console.log('desktop!');
  * }
  * ```
  *
  * **Retrieving a list of matching media queries**
  *
  * It's also nice to be able to see which media queries are matching, since
  * some applications might have many matches at the same time.
  *
  * ```javascript
  * media = Ember.Responsive.Media.create();
  * media.match('desktop', 'all');
  * media.match('mobile', 'all');
  *
  * console.log(media.matches);
  * // => Ember.Set(['desktop', 'mobile']);
  * ```
  *
  * This class can also return that list as a string of dasherized class names,
  * which is useful for placing on your app's rootElement. By default, these
  * class names are prefixed with `media-`, so as not to clash with any other
  * classes your app might use.
  *
  * ```javascript
  * App.ApplicationView = Ember.View.extend({
  *   classNameBindings: ['media.classNames']
  * });
  * ```
  *
  * @module    ember-responsive
  * @namespace Ember.Responsive
  * @class     Media
  * @extends   Ember.Object
  */
  let MediaService = _exports.default = (_class = class MediaService extends _service.default.extend(_evented.default) {
    /**
    * A set of matching matchers.
    *
    * @property  matches
    * @type      Array
    */
    get matches() {
      if (this._matches) {
        return this._matches;
      }
      return _ember.default.testing && this._mocked ? [this._mockedBreakpoint] : [];
    }
    set matches(value) {
      this._matches = value;
    }

    /**
    * A hash of listeners indexed by their matcher's names
    *
    * @property
    * @type Object
    */

    /**
     * Initialize the service based on the breakpoints config
     *
     * @method init
     *
     */
    constructor() {
      super(...arguments);
      // Ember only sets Ember.testing when tests are starting
      // eslint-disable-next-line ember/no-ember-testing-in-module-scope
      _defineProperty(this, "_mocked", _ember.default.testing);
      _defineProperty(this, "_mockedBreakpoint", 'desktop');
      /**
      * @property  _matches
      * @type      Array
      */
      _initializerDefineProperty(this, "_matches", _descriptor, this);
      _defineProperty(this, "listeners", {});
      /**
       * A hash of matchers by breakpoint name
       */
      _defineProperty(this, "matchers", {});
      /**
      * The matcher to use for testing media queries.
      *
      * @property  matcher
      * @type      matchMedia
      * @default   window.matchMedia
      * @private
      */
      _defineProperty(this, "mql", detectMatchMedia());
      const breakpoints = (0, _application.getOwner)(this).lookup('breakpoints:main');
      if (breakpoints) {
        Object.keys(breakpoints).forEach(name => {
          const cpName = `is${(0, _string.classify)(name)}`;
          (0, _object.defineProperty)(this, cpName, (0, _compat.dependentKeyCompat)({
            get() {
              return this.matches.indexOf(name) > -1;
            }
          }));
          (0, _object.defineProperty)(this, name, (0, _compat.dependentKeyCompat)({
            get() {
              return this[cpName];
            }
          }));
          this.match(name, breakpoints[name]);
        });
      }
    }

    /**
    * A string composed of all the matching matchers' names, turned into
    * friendly, dasherized class-names that are prefixed with `media-`.
    *
    * @property  classNames
    * @type      string
    */
    get classNames() {
      return this.matches.map(function (name) {
        return `media-${(0, _string.dasherize)(name)}`;
      }).join(' ');
    }
    _triggerMediaChanged() {
      this.trigger('mediaChanged', {});
    }
    _triggerEvent() {
      (0, _runloop.once)(this, this._triggerMediaChanged);
    }

    /**
    * Adds a new matcher to the list.
    *
    * After this method is called, you will be able to access the result
    * of the matcher as a property on this object.
    *
    * **Adding a new matcher**
    *
    * ```javascript
    * media = Ember.Responsive.Media.create();
    * media.match('all', 'all');
    * media.get('all');
    *   // => instanceof window.matchMedia
    * media.get('all.matches');
    *   // => true
    * ```
    *
    * @param   string  name   The name of the matcher
    * @param   string  query  The media query to match against
    * @method  match
    */
    match(name, query) {
      // see https://github.com/ember-cli/eslint-plugin-ember/pull/272
      if (_ember.default.testing && this._mocked) {
        return;
      }
      const mql = this.mql;
      const matcher = mql(query);
      const listener = matcher => {
        if (this.isDestroyed) {
          return;
        }
        (0, _object.set)(this, `matchers.${name}`, matcher);
        if (matcher.matches) {
          this.matches = Array.from(new Set([...this.matches, name]));
        } else {
          this.matches = Array.from(new Set(this.matches.filter(key => key !== name)));
        }
        this._triggerEvent();
      };
      this.listeners[name] = listener;
      if (matcher.addListener) {
        matcher.addListener(function (matcher) {
          (0, _runloop.run)(null, listener, matcher);
        });
      }
      listener(matcher);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_matches", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  function detectMatchMedia() {
    if (typeof window === 'object' && window.matchMedia) {
      return window.matchMedia;
    }
    return _nullMatchMedia.default;
  }
});