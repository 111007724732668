define("@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/helpers/get-article-tags", ["exports", "@ember/component/helper", "@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/models/article-base"], function (_exports, _helper, _articleBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GetArticleTagsHelper extends _helper.default {
    compute(_ref) {
      let [article, locale, defaultValue = null] = _ref;
      /* istanbul ignore if */
      if (!article || !(article instanceof _articleBase.default)) {
        throw new Error('You should pass an instance of an article model to the `get-tags` helper');
      }
      return article.localized_tags.find(t => t.language_code === locale) || defaultValue;
    }
  }
  _exports.default = GetArticleTagsHelper;
});