define("@ukgepic/hrsd-ember-ignite/modifiers/auto-present-dismiss", ["exports", "ember-modifier", "@ember/destroyable"], function (_exports, _emberModifier, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function removeElement(instance) {
    instance.element?.dismiss();
  }
  class DialogPresent extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "element", null);
      (0, _destroyable.registerDestructor)(this, removeElement);
    }
    modify(element) {
      // Save off the element the first time for convenience with #moveElement
      if (!this.element) {
        this.element = element;
        this.element.present();
      }
    }
  }
  _exports.default = DialogPresent;
});