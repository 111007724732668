define("@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-empty", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span ...attributes class="ignite-data-table-empty">{{yield}}</span>
  */
  {
    "id": "9/9bUMnE",
    "block": "[[[11,1],[17,1],[24,0,\"ignite-data-table-empty\"],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@ukgepic/hrsd-ember-ignite/components/ignite-paginated-data-table/-empty.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});