define("ember-linkify/helpers/linkify", ["exports", "@ember/utils", "@ember/component/helper", "@ember/template", "ember", "ember-linkify/utils/url-regex"], function (_exports, _utils, _helper, _template, _ember, _urlRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.linkify = linkify;
  const ALLOWED_ATTRIBUTE_NAMES = ['rel', 'class', 'target'];
  function linkify(params, options) {
    let textToLinkify = _ember.default.Handlebars.Utils.escapeExpression(params[0]);
    const sharedAttributes = opts2attrs(options);
    textToLinkify = textToLinkify.replace((0, _urlRegex.urlRegex)(), function (s) {
      let url;
      let displayText = s.trim();
      if (s.trim().match(/^www\./gi)) {
        if (options && options.defaultScheme) {
          url = options.defaultScheme + '://' + s.trim();
        } else {
          url = '//' + s.trim();
        }
      } else {
        url = s.trim();
      }
      if (options && options.urlLength && options.urlLength > 0) {
        displayText = (0, _urlRegex.shortenUrl)(displayText, options.urlLength);
      }
      return `<a href="${url}"${sharedAttributes}>${displayText}</a>`;
    });
    return (0, _template.htmlSafe)(textToLinkify);
  }
  var _default = _exports.default = (0, _helper.helper)(linkify);
  function opts2attrs(options) {
    const stringOfAttributes = [''];
    if ((0, _utils.typeOf)(options) === 'object') {
      for (let i = 0; i < ALLOWED_ATTRIBUTE_NAMES.length; i++) {
        const attributeName = ALLOWED_ATTRIBUTE_NAMES[i];
        if (attributeName in options) {
          stringOfAttributes.push(`${attributeName}="${options[attributeName]}"`);
        }
      }
    }
    return stringOfAttributes.join(' ');
  }
});