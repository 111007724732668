define("ember-concurrency/-private/external/task-decorators", ["exports", "ember-concurrency/-private/external/task-factory"], function (_exports, _taskFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTaskDecorator = createTaskDecorator;
  _exports.createTaskGroupDecorator = createTaskGroupDecorator;
  _exports.decoratorWithParams = decoratorWithParams;
  _exports.lastValue = void 0;
  function taskFromPropertyDescriptor(target, key, descriptor) {
    let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let factoryClass = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _taskFactory.TaskFactory;
    let {
      initializer,
      get,
      value
    } = descriptor;
    let taskFn;
    if (initializer) {
      taskFn = initializer.call(undefined);
    } else if (get) {
      taskFn = get.call(undefined);
    } else if (value) {
      taskFn = value;
    }
    taskFn.displayName = `${key} (task)`;
    let tasks = new WeakMap();
    let options = params[0] || {};
    let factory = new factoryClass(key, taskFn, options);
    factory._setupEmberKVO(target);
    return {
      get() {
        let task = tasks.get(this);
        if (!task) {
          task = factory.createTask(this);
          tasks.set(this, task);
        }
        return task;
      }
    };
  }
  function taskGroupPropertyDescriptor(_target, key, _descriptor) {
    let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let factoryClass = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _taskFactory.TaskFactory;
    let taskGroups = new WeakMap();
    let options = params[0] || {};
    let factory = new factoryClass(key, null, options);
    return {
      get() {
        let task = taskGroups.get(this);
        if (!task) {
          task = factory.createTaskGroup(this);
          taskGroups.set(this, task);
        }
        return task;
      }
    };
  }

  // Cribbed from @ember-decorators/utils
  function isFieldDescriptor(possibleDesc) {
    let [target, key, desc] = possibleDesc;
    return possibleDesc.length === 3 && typeof target === 'object' && target !== null && typeof key === 'string' && (typeof desc === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility ???
    ;
  }

  function decoratorWithParams(descriptorFn) {
    return function () {
      for (var _len = arguments.length, params = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
        params[_key2] = arguments[_key2];
      }
      if (isFieldDescriptor(params)) {
        return descriptorFn(...params);
      } else {
        return function () {
          for (var _len2 = arguments.length, desc = new Array(_len2), _key3 = 0; _key3 < _len2; _key3++) {
            desc[_key3] = arguments[_key3];
          }
          return descriptorFn(...desc, params);
        };
      }
    };
  }
  function createDecorator(fn) {
    let baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let factoryClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _taskFactory.TaskFactory;
    return decoratorWithParams(function (target, key, descriptor) {
      let [userOptions] = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      let mergedOptions = Object.assign({}, {
        ...baseOptions,
        ...userOptions
      });
      return fn(target, key, descriptor, [mergedOptions], factoryClass);
    });
  }
  function createTaskDecorator() {
    let baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let factoryClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _taskFactory.TaskFactory;
    return createDecorator(taskFromPropertyDescriptor, baseOptions, factoryClass);
  }
  function createTaskGroupDecorator() {
    let baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let factoryClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _taskFactory.TaskFactory;
    return createDecorator(taskGroupPropertyDescriptor, baseOptions, factoryClass);
  }
  const lastValue = _exports.lastValue = decoratorWithParams(function (_target, _key, descriptor) {
    let [taskName] = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    const {
      initializer
    } = descriptor;
    delete descriptor.initializer;
    return {
      get() {
        let lastInstance = this[taskName].lastSuccessful;
        if (lastInstance) {
          return lastInstance.value;
        }
        if (initializer) {
          return initializer.call(this);
        }
        return undefined;
      }
    };
  });
});