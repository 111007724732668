define("@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/serializers/article", ["exports", "@ukgepic/hrsd-ember-hrsd-knowledgebase-tools/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      versions: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });
});